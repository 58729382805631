define('ember-content-placeholders/components/content-placeholders-icon', ['exports', 'ember-content-placeholders/components/content-placeholders-base', 'ember-content-placeholders/templates/components/content-placeholders-icon'], function (exports, _contentPlaceholdersBase, _contentPlaceholdersIcon) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _contentPlaceholdersBase.default.extend({
    className: 'ember-content-placeholders-icon',
    classNameBindings: ['className'],
    layout: _contentPlaceholdersIcon.default
  });
});