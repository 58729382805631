define("osf-components/components/node-doi-create/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HHL/YD9O",
    "block": "{\"symbols\":[\"@manager\"],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[[28,\"local-class\",[\"CreateMessage\"],[[\"from\"],[\"osf-components/components/node-doi-create/styles\"]]]]]],[8],[0,\"\\n    \"],[5,\"fa-icon\",[],[[\"@icon\",\"@size\",\"@fixedWidth\"],[\"info-circle\",\"4\",true]]],[0,\"\\n    \"],[7,\"div\",true],[8],[0,\"\\n        \"],[7,\"p\",true],[8],[1,[28,\"t\",[\"registries.registration_metadata.mint_doi.header\"],null],false],[9],[0,\"\\n        \"],[7,\"p\",true],[8],[1,[28,\"t\",[\"registries.registration_metadata.mint_doi.text\"],null],false],[9],[0,\"\\n    \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[11,\"class\",[29,[[28,\"local-class\",[\"CreateButton\"],[[\"from\"],[\"osf-components/components/node-doi-create/styles\"]]]]]],[8],[0,\"\\n    \"],[5,\"osf-button\",[[12,\"data-analytics-name\",\"Create DOI\"],[12,\"data-test-create-doi\",\"\"],[12,\"disabled\",[23,1,[\"requestDoi\",\"isRunning\"]]]],[[\"@type\",\"@onClick\"],[\"primary\",[28,\"action\",[[23,0,[]],[23,1,[\"requestDoi\"]]],null]]],{\"statements\":[[0,\"\\n        \"],[1,[28,\"t\",[\"registries.registration_metadata.create_doi\"],null],false],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/node-doi-create/template.hbs"
    }
  });

  _exports.default = _default;
});