define("osf-components/components/validated-input/custom/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "N4NLg074",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"validated-input/x-input-wrapper\",null,[[\"model\",\"changeset\",\"errors\",\"valuePath\",\"validationStatus\"],[[23,0,[\"model\"]],[23,0,[\"changeset\"]],[23,0,[\"errors\"]],[23,0,[\"valuePath\"]],[23,0,[\"validationStatus\"]]]],{\"statements\":[[0,\"    \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/validated-input/custom/template.hbs"
    }
  });

  _exports.default = _default;
});