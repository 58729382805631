define("osf-components/components/carousel/x-header/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "visually-hidden": "_visually-hidden_1tjv2m"
  };
  _exports.default = _default;
});