define("osf-components/components/file-list-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OHUWa7jk",
    "block": "{\"symbols\":[\"@item\"],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"row \",[28,\"local-class\",[\"file-row-item\"],[[\"from\"],[\"osf-components/components/file-list-item/styles\"]]]]]],[10,\"data-analytics-scope\",\"File list item\"],[10,\"data-analytics-name\",\"Open file\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],[23,0,[\"open\"]]],null]],[10,\"role\",\"option\"],[8],[0,\"\\n    \"],[7,\"div\",true],[11,\"class\",[29,[\"col-lg-12 col-md-9 col-sm-9 col-xs-12 \",[28,\"local-class\",[\"file-row-col\"],[[\"from\"],[\"osf-components/components/file-list-item/styles\"]]]]]],[10,\"data-test-file-item\",\"\"],[8],[0,\"\\n        \"],[1,[28,\"file-icon\",null,[[\"item\"],[[23,1,[]]]]],false],[0,\"\\n        \"],[5,\"osf-link\",[[12,\"data-test-file-item-link\",[23,1,[\"itemName\"]]],[12,\"data-analytics-name\",\"Open file\"]],[[\"@route\",\"@models\",\"@onClick\"],[\"guid-file\",[28,\"array\",[[23,1,[\"guid\"]]],null],[28,\"action\",[[23,0,[]],[23,0,[\"open\"]]],null]]],{\"statements\":[[0,\"\\n            \"],[1,[23,1,[\"itemName\"]],false],[0,\"\\n        \"]],\"parameters\":[]}],[0,\"\\n    \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/file-list-item/template.hbs"
    }
  });

  _exports.default = _default;
});