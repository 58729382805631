define("osf-components/components/validated-input/checkbox/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Q8OEh+gO",
    "block": "{\"symbols\":[\"inputElementId\",\"&default\"],\"statements\":[[4,\"validated-input/x-input-wrapper\",null,[[\"model\",\"changeset\",\"errors\",\"label\",\"valuePath\",\"validationStatus\"],[[23,0,[\"model\"]],[23,0,[\"changeset\"]],[23,0,[\"errors\"]],[23,0,[\"label\"]],[23,0,[\"valuePath\"]],[23,0,[\"validationStatus\"]]]],{\"statements\":[[0,\"    \"],[7,\"label\",true],[8],[0,\"\\n        \"],[5,\"input\",[],[[\"@id\",\"@type\",\"@checked\",\"@name\",\"@disabled\"],[[23,1,[]],\"checkbox\",[23,0,[\"value\"]],[23,0,[\"valuePath\"]],[23,0,[\"disabled\"]]]]],[0,\"\\n        \"],[14,2],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/validated-input/checkbox/template.hbs"
    }
  });

  _exports.default = _default;
});