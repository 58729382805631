define("osf-components/components/node-card/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "NodeCard__heading": "_NodeCard__heading_1i3kzz",
    "NodeCard__body": "_NodeCard__body_1i3kzz",
    "NodeCard__dropdown": "_NodeCard__dropdown_1i3kzz",
    "NodeCard__dropdown-button": "_NodeCard__dropdown-button_1i3kzz",
    "NodeCard__dropdown-list": "_NodeCard__dropdown-list_1i3kzz",
    "NodeCard__dropdown-link": "_NodeCard__dropdown-link_1i3kzz",
    "NodeCard__authors": "_NodeCard__authors_1i3kzz",
    "NodeCard__description": "_NodeCard__description_1i3kzz",
    "NodeCard__tags": "_NodeCard__tags_1i3kzz"
  };
  _exports.default = _default;
});