define("osf-components/components/feedback-button/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "FeedbackButton": "_FeedbackButton_1qyozy",
    "FeedbackButton--open": "_FeedbackButton--open_1qyozy",
    "Modal": "_Modal_1qyozy"
  };
  _exports.default = _default;
});