define("osf-components/components/osf-dialog/heading/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AzBz2pQi",
    "block": "{\"symbols\":[\"@headingId\",\"&attrs\",\"&default\",\"@closeDialog\"],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[[28,\"local-class\",[\"HeadingWrapper\"],[[\"from\"],[\"osf-components/components/osf-dialog/heading/styles\"]]]]]],[8],[0,\"\\n    \"],[7,\"h2\",false],[12,\"class\",[29,[[28,\"local-class\",[\"Heading\"],[[\"from\"],[\"osf-components/components/osf-dialog/heading/styles\"]]]]]],[12,\"id\",[23,1,[]]],[13,2],[8],[0,\"\\n        \"],[14,3],[0,\"\\n    \"],[9],[0,\"\\n    \"],[5,\"osf-button\",[[12,\"class\",[29,[[28,\"local-class\",[\"CloseButton\"],[[\"from\"],[\"osf-components/components/osf-dialog/heading/styles\"]]]]]],[12,\"data-test-close-dialog\",\"\"],[12,\"data-analytics-name\",\"Close dialog\"],[12,\"aria-label\",[28,\"t\",[\"general.close\"],null]]],[[\"@type\",\"@onClick\"],[\"link\",[28,\"action\",[[23,0,[]],[23,4,[]]],null]]],{\"statements\":[[0,\"\\n        \"],[5,\"fa-icon\",[],[[\"@icon\"],[\"times\"]]],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/osf-dialog/heading/template.hbs"
    }
  });

  _exports.default = _default;
});