define("osf-components/components/files/menu/create-folder/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RrWTUxbk",
    "block": "{\"symbols\":[\"dialog\",\"form\",\"@isOpen\",\"@closeDialog\",\"@changeset\",\"@createFolder\",\"@shouldDisableButtons\"],\"statements\":[[5,\"osf-dialog\",[],[[\"@isOpen\",\"@onClose\"],[[23,3,[]],[23,4,[]]]],{\"statements\":[[0,\"\\n    \"],[6,[23,1,[\"heading\"]],[],[[],[]],{\"statements\":[[0,\"\\n        \"],[1,[28,\"t\",[\"osf-components.files-widget.create_folder\"],null],false],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n    \"],[6,[23,1,[\"main\"]],[[12,\"class\",[29,[[28,\"local-class\",[\"dialog-main\"],[[\"from\"],[\"osf-components/components/files/menu/create-folder/styles\"]]]]]]],[[],[]],{\"statements\":[[0,\"\\n        \"],[5,\"form-controls\",[],[[\"@changeset\"],[[23,5,[]]]],{\"statements\":[[0,\"\\n            \"],[6,[23,2,[\"text\"]],[],[[\"@placeholder\",\"@valuePath\"],[[28,\"t\",[\"osf-components.files-widget.folder_name_placeholder\"],null],\"name\"]]],[0,\"\\n        \"]],\"parameters\":[2]}],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n    \"],[6,[23,1,[\"footer\"]],[],[[],[]],{\"statements\":[[0,\"\\n        \"],[5,\"osf-button\",[],[[\"@type\",\"@onClick\"],[\"default\",[23,1,[\"close\"]]]],{\"statements\":[[0,\"\\n            \"],[1,[28,\"t\",[\"general.cancel\"],null],false],[0,\"\\n        \"]],\"parameters\":[]}],[0,\"\\n        \"],[5,\"osf-button\",[],[[\"@type\",\"@onClick\",\"@disabled\"],[\"primary\",[28,\"action\",[[23,0,[]],[23,6,[]],[28,\"hash\",null,[[\"onSuccess\"],[[23,1,[\"close\"]]]]]],null],[23,7,[]]]],{\"statements\":[[0,\"\\n            \"],[1,[28,\"t\",[\"osf-components.files-widget.create\"],null],false],[0,\"\\n        \"]],\"parameters\":[]}],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/files/menu/create-folder/template.hbs"
    }
  });

  _exports.default = _default;
});