define("osf-components/components/sign-up-form/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "SignUpForm": "_SignUpForm_3ntsx4",
    "help-block": "_help-block_3ntsx4",
    "tos-checkbox": "_tos-checkbox_3ntsx4",
    "CreatePassword": "_CreatePassword_3ntsx4",
    "RecaptchaParent": "_RecaptchaParent_3ntsx4"
  };
  _exports.default = _default;
});