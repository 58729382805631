define("osf-components/components/subjects/manager/single/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dWBzMSB8",
    "block": "{\"symbols\":[\"@subjectsManager\",\"&default\"],\"statements\":[[14,2,[[28,\"hash\",null,[[\"subject\",\"isSelected\",\"isSaved\",\"hasSelectedChildren\",\"isLoaded\",\"numChildren\",\"children\",\"toggleSelected\",\"ensureChildrenLoaded\",\"ChildSubjectManager\"],[[23,0,[\"subject\"]],[23,0,[\"isSelected\"]],[23,0,[\"isSaved\"]],[23,0,[\"hasSelectedChildren\"]],[23,0,[\"isLoaded\"]],[23,0,[\"numChildren\"]],[23,0,[\"children\"]],[28,\"action\",[[23,0,[]],[23,0,[\"toggleSelected\"]]],null],[28,\"action\",[[23,0,[]],[23,0,[\"ensureChildrenLoaded\"]]],null],[28,\"component\",[\"subjects/manager/single\"],[[\"subjectsManager\"],[[23,1,[]]]]]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/subjects/manager/single/template.hbs"
    }
  });

  _exports.default = _default;
});