define("osf-components/components/registries/schema-block-renderer/read-only/response/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "ResponseValue": "_ResponseValue_17j1v8 _Element_t890a9",
    "NoResponse": "_NoResponse_17j1v8",
    "ValidationErrors": "_ValidationErrors_17j1v8 _Element_t890a9"
  };
  _exports.default = _default;
});