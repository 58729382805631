define("osf-components/components/contributors/widget/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "j2ezwATv",
    "block": "{\"symbols\":[\"manager\",\"&attrs\",\"@node\"],\"statements\":[[7,\"div\",false],[12,\"class\",[29,[[28,\"local-class\",[\"Container\"],[[\"from\"],[\"osf-components/components/contributors/widget/styles\"]]]]]],[13,2],[8],[0,\"\\n    \"],[7,\"div\",true],[11,\"class\",[29,[[28,\"local-class\",[\"Heading\"],[[\"from\"],[\"osf-components/components/contributors/widget/styles\"]]]]]],[8],[0,\"\\n        \"],[7,\"span\",true],[11,\"class\",[29,[[28,\"local-class\",[\"HeadingTitle\"],[[\"from\"],[\"osf-components/components/contributors/widget/styles\"]]]]]],[10,\"data-test-heading-name\",\"\"],[8],[0,\"\\n            \"],[1,[28,\"t\",[\"osf-components.contributors.headings.name\"],null],false],[0,\"\\n        \"],[9],[0,\"\\n        \"],[7,\"span\",true],[11,\"class\",[29,[[28,\"local-class\",[\"HeadingTitle\"],[[\"from\"],[\"osf-components/components/contributors/widget/styles\"]]]]]],[10,\"data-test-heading-permission\",\"\"],[8],[0,\"\\n            \"],[1,[28,\"t\",[\"osf-components.contributors.headings.permission\"],null],false],[0,\"\\n        \"],[9],[0,\"\\n        \"],[7,\"span\",true],[11,\"class\",[29,[[28,\"local-class\",[\"HeadingTitle\"],[[\"from\"],[\"osf-components/components/contributors/widget/styles\"]]]]]],[10,\"data-test-heading-citation\",\"\"],[8],[0,\"\\n            \"],[1,[28,\"t\",[\"osf-components.contributors.headings.citation\"],null],false],[0,\"\\n        \"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[5,\"contributors/manager\",[],[[\"@node\"],[[23,3,[]]]],{\"statements\":[[0,\"\\n        \"],[5,\"contributors/list\",[],[[\"@contributorsManager\",\"@widgetMode\"],[[23,1,[]],[23,0,[\"widgetMode\"]]]]],[0,\"\\n    \"]],\"parameters\":[1]}],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/contributors/widget/template.hbs"
    }
  });

  _exports.default = _default;
});