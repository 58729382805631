define("osf-components/components/registries/schema-block-renderer/page-heading/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xMRKMFvj",
    "block": "{\"symbols\":[\"@schemaBlock\",\"&attrs\"],\"statements\":[[7,\"h2\",false],[12,\"class\",[29,[[28,\"local-class\",[\"PageHeading\"],[[\"from\"],[\"osf-components/components/registries/schema-block-renderer/page-heading/styles\"]]]]]],[12,\"data-test-page-heading\",\"\"],[12,\"id\",[23,1,[\"elementId\"]]],[13,2],[8],[0,\"\\n    \"],[1,[23,0,[\"localizedDisplayText\"]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[4,\"if\",[[23,0,[\"isEditableForm\"]]],null,{\"statements\":[[0,\"    \"],[7,\"p\",true],[11,\"class\",[29,[[28,\"local-class\",[\"PageHeading_helper\"],[[\"from\"],[\"osf-components/components/registries/schema-block-renderer/page-heading/styles\"]]]]]],[8],[1,[23,0,[\"localizedHelpText\"]],false],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/registries/schema-block-renderer/page-heading/template.hbs"
    }
  });

  _exports.default = _default;
});