define("osf-components/components/banners/view-only-link/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "L8ltKX17",
    "block": "{\"symbols\":[],\"statements\":[[5,\"bs-alert\",[[12,\"class\",[29,[[28,\"local-class\",[\"Alert\"],[[\"from\"],[\"osf-components/components/banners/view-only-link/styles\"]]]]]],[12,\"data-analytics-scope\",\"View-only link banner\"]],[[\"@visible\",\"@type\"],[[23,0,[\"shouldDisplay\"]],\"info\"]],{\"statements\":[[0,\"\\n    \"],[7,\"div\",true],[11,\"class\",[29,[[28,\"local-class\",[\"Banner\"],[[\"from\"],[\"osf-components/components/banners/view-only-link/styles\"]]]]]],[8],[0,\"\\n        \"],[7,\"p\",true],[8],[0,\"\\n            \"],[1,[28,\"t\",[\"banners.view_only.warning\"],null],false],[0,\"\\n        \"],[9],[0,\"\\n\\n        \"],[5,\"osf-button\",[[12,\"data-test-view-normally\",\"\"],[12,\"data-analytics-name\",\"Stop view-only link\"]],[[\"@type\",\"@onClick\"],[\"default\",[28,\"action\",[[23,0,[]],[23,0,[\"stopViewOnly\"]]],null]]],{\"statements\":[[0,\"\\n            \"],[1,[28,\"t\",[\"banners.view_only.view_normally\"],null],false],[0,\"\\n        \"]],\"parameters\":[]}],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/banners/view-only-link/template.hbs"
    }
  });

  _exports.default = _default;
});