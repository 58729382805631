define("osf-components/components/panel/x-body/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Oi9RoNZm",
    "block": "{\"symbols\":[\"&default\",\"@text\"],\"statements\":[[7,\"div\",true],[10,\"data-test-panel-body\",\"\"],[10,\"class\",\"panel-body\"],[8],[0,\"\\n\"],[4,\"if\",[[23,2,[]]],null,{\"statements\":[[0,\"        \"],[1,[23,2,[]],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"        \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/panel/x-body/template.hbs"
    }
  });

  _exports.default = _default;
});