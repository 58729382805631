define("osf-components/components/registries/schema-block-renderer/editable/textarea/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "schema-block-long-text": "_schema-block-long-text_hncwdp"
  };
  _exports.default = _default;
});