define("osf-components/components/registries/schema-block-renderer/editable/rdm/jgn-program-name-en-input/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EsKcvSJj",
    "block": "{\"symbols\":[\"form\",\"@changeset\",\"@disabled\",\"@shouldShowMessages\",\"&attrs\",\"@uniqueID\",\"@schemaBlock\"],\"statements\":[[5,\"registries/schema-block-renderer/editable/base\",[],[[\"@helpText\"],[[23,0,[\"schemaBlock\",\"helpText\"]]]],{\"statements\":[[0,\"\\n    \"],[5,\"form-controls\",[[13,5]],[[\"@changeset\",\"@disabled\",\"@shouldShowMessages\"],[[23,2,[]],[23,3,[]],[23,4,[]]]],{\"statements\":[[0,\"\\n        \"],[6,[23,1,[\"text\"]],[[12,\"class\",[29,[[28,\"local-class\",[\"schema-block-input\"],[[\"from\"],[\"osf-components/components/registries/schema-block-renderer/editable/rdm/jgn-program-name-en-input/styles\"]]]]]],[12,\"data-test-jgn-program-name-en-input\",\"\"]],[[\"@uniqueID\",\"@valuePath\",\"@placeholder\"],[[23,6,[]],[23,7,[\"registrationResponseKey\"]],\" \"]]],[0,\"\\n    \"]],\"parameters\":[1]}],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/registries/schema-block-renderer/editable/rdm/jgn-program-name-en-input/template.hbs"
    }
  });

  _exports.default = _default;
});