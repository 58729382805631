define("osf-components/components/side-nav/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eDiajKcv",
    "block": "{\"symbols\":[\"SideNavLink\",\"&attrs\",\"@onLinkClicked\",\"&default\"],\"statements\":[[7,\"nav\",false],[12,\"class\",[29,[[28,\"local-class\",[[28,\"concat\",[\"SideNav \",[28,\"if\",[[23,0,[\"isCollapsed\"]],\"Collapsed\"],null]],null]],[[\"from\"],[\"osf-components/components/side-nav/styles\"]]]]]],[12,\"data-test-side-nav\",\"1\"],[12,\"data-test-collapsed\",[29,[[23,0,[\"isCollapsed\"]]]]],[13,2],[8],[0,\"\\n    \"],[7,\"div\",true],[11,\"class\",[29,[[28,\"local-class\",[\"Links\"],[[\"from\"],[\"osf-components/components/side-nav/styles\"]]]]]],[8],[0,\"\\n        \"],[14,4,[[28,\"hash\",null,[[\"link\"],[[28,\"component\",[\"side-nav/x-link\"],[[\"onClick\",\"isCollapsed\"],[[23,3,[]],[23,0,[\"isCollapsed\"]]]]]]]]]],[0,\"\\n    \"],[9],[0,\"\\n\\n\"],[4,\"if\",[[23,0,[\"isCollapseAllowed\"]]],null,{\"statements\":[[4,\"let\",[[28,\"component\",[\"side-nav/x-link\"],null]],null,{\"statements\":[[0,\"            \"],[6,[23,1,[]],[[12,\"data-analytics-name\",[28,\"if\",[[23,0,[\"isCollapsed\"]],\"Expand\",\"Collapse\"],null]]],[[\"@onClick\",\"@isCollapsed\",\"@icon\",\"@label\"],[[28,\"action\",[[23,0,[]],[23,0,[\"toggle\"]]],null],[23,0,[\"isCollapsed\"]],[28,\"if\",[[23,0,[\"isCollapsed\"]],\"chevron-right\",\"chevron-left\"],null],[28,\"t\",[[28,\"if\",[[23,0,[\"isCollapsed\"]],\"registries.overview.expand\",\"registries.overview.collapse\"],null]],null]]]],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/side-nav/template.hbs"
    }
  });

  _exports.default = _default;
});