define("ember-cli-sanitize-html/templates/components/sanitize-html", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "El8MnEUP",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"  \"],[14,1,[[24,[\"sanitizedValue\"]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"raw\"]]],null,{\"statements\":[[0,\"    \"],[1,[22,\"sanitizedValue\"],true],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[22,\"sanitizedValue\"],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-cli-sanitize-html/templates/components/sanitize-html.hbs"
    }
  });

  _exports.default = _default;
});