define("osf-components/components/carousel/x-header/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LVX4B2UH",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[7,\"header\",false],[12,\"class\",[29,[[28,\"local-class\",[\"visually-hidden\"],[[\"from\"],[\"osf-components/components/carousel/x-header/styles\"]]]]]],[13,1],[8],[14,2],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/carousel/x-header/template.hbs"
    }
  });

  _exports.default = _default;
});