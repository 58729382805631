define("osf-components/components/osf-dialog/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "DialogBackground": "_DialogBackground_1hjedp",
    "Unclickable": "_Unclickable_1hjedp",
    "Dialog": "_Dialog_1hjedp",
    "DialogDocument": "_DialogDocument_1hjedp",
    "FixedWidth": "_FixedWidth_1hjedp"
  };
  _exports.default = _default;
});