define("registries/routes", ["exports", "ember-engines/routes"], function (_exports, _routes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _routes.default)(function () {
    this.route('index', {
      path: '/registries'
    });
    this.route('discover', {
      path: '/registries/discover'
    });
    this.route('start', {
      path: '/registries/start'
    });
    this.route('forms', {
      path: '/registries/forms'
    }, function () {
      this.route('help');
    });
    this.route('drafts', {
      path: '/registries/drafts'
    }, function () {
      this.route('draft', {
        path: '/:id'
      }, function () {
        this.route('page', {
          path: '/:page'
        });
        this.route('review');
        this.route('metadata');
      });
    });
    this.route('overview', {
      path: '/:guid'
    }, function () {
      this.route('analytics');
      this.route('children', {
        path: '/components'
      });
      this.route('comments');
      this.route('forks');
      this.route('links');
    });
    this.route('page-not-found', {
      path: '/*path'
    });
  });

  _exports.default = _default;
});