define("osf-components/components/registries/schema-block-renderer/helper-text-icon/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DGUqWOSR",
    "block": "{\"symbols\":[\"&attrs\"],\"statements\":[[4,\"if\",[[23,0,[\"helpText\"]]],null,{\"statements\":[[0,\"    \"],[5,\"fa-icon\",[[12,\"class\",[29,[[28,\"local-class\",[\"helper-text-icon\"],[[\"from\"],[\"osf-components/components/registries/schema-block-renderer/helper-text-icon/styles\"]]]]]],[12,\"tabindex\",\"0\"],[13,1]],[[\"@icon\",\"@ariaHidden\"],[\"question-circle\",false]],{\"statements\":[[0,\"\\n        \"],[5,\"bs-popover\",[],[[\"@triggerEvents\"],[[28,\"array\",[\"hover\",\"focus\"],null]]],{\"statements\":[[0,\"\\n            \"],[1,[23,0,[\"helpText\"]],false],[0,\"\\n        \"]],\"parameters\":[]}],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/registries/schema-block-renderer/helper-text-icon/template.hbs"
    }
  });

  _exports.default = _default;
});