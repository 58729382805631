define("osf-components/components/form-controls/radio-button-group/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "RadioButtonGroup": "_RadioButtonGroup_nqj974",
    "RadioButton": "_RadioButton_nqj974",
    "RadioLabel": "_RadioLabel_nqj974"
  };
  _exports.default = _default;
});