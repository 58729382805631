define("osf-components/components/node-description/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "Description__field": "_Description__field_1a66rm",
    "DescriptionWrapper": "_DescriptionWrapper_1a66rm",
    "hide": "_hide_1a66rm",
    "DescriptionOverlay": "_DescriptionOverlay_1a66rm",
    "collapsed": "_collapsed_1a66rm"
  };
  _exports.default = _default;
});