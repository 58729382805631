define("osf-components/components/file-share-button/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "Popover": "_Popover_1s38w0",
    "SharePane__button-group": "_SharePane__button-group_1s38w0",
    "SharePane__share-link": "_SharePane__share-link_1s38w0",
    "SharePane__tab": "_SharePane__tab_1s38w0"
  };
  _exports.default = _default;
});