define("osf-components/components/file-list-item/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "FileListItem": "_FileListItem_5qwau1",
    "selected": "_selected_5qwau1",
    "file-row-item": "_file-row-item_5qwau1",
    "file-row-col": "_file-row-col_5qwau1"
  };
  _exports.default = _default;
});