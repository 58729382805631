define("osf-components/components/subjects/manager/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PThOHNcv",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[28,\"hash\",null,[[\"selectedSubjects\",\"savedSubjects\",\"isSaving\",\"hasChanged\",\"provider\",\"selectSubject\",\"unselectSubject\",\"saveChanges\",\"discardChanges\",\"subjectIsSelected\",\"subjectIsSaved\",\"subjectHasSelectedChildren\"],[[23,0,[\"selectedSubjects\"]],[23,0,[\"savedSubjects\"]],[23,0,[\"saveChanges\",\"isRunning\"]],[23,0,[\"hasChanged\"]],[23,0,[\"provider\"]],[28,\"action\",[[23,0,[]],[23,0,[\"selectSubject\"]]],null],[28,\"action\",[[23,0,[]],[23,0,[\"unselectSubject\"]]],null],[28,\"perform\",[[23,0,[\"saveChanges\"]]],null],[28,\"action\",[[23,0,[]],[23,0,[\"discardChanges\"]]],null],[28,\"action\",[[23,0,[]],[23,0,[\"subjectIsSelected\"]]],null],[28,\"action\",[[23,0,[]],[23,0,[\"subjectIsSaved\"]]],null],[28,\"action\",[[23,0,[]],[23,0,[\"subjectHasSelectedChildren\"]]],null]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/subjects/manager/template.hbs"
    }
  });

  _exports.default = _default;
});