define("osf-components/components/contributors/list/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "ContributorList": "_ContributorList_eq43gl"
  };
  _exports.default = _default;
});