define("osf-components/components/inline-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8yu2zJx8",
    "block": "{\"symbols\":[\"item\",\"index\",\"&default\"],\"statements\":[[4,\"if\",[[28,\"eq\",[[23,0,[\"totalCount\"]],1],null]],null,{\"statements\":[[0,\"\\n    \"],[14,3,[[28,\"hash\",null,[[\"item\"],[[23,0,[\"firstItem\"]]]]]]],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"eq\",[[23,0,[\"totalCount\"]],2],null]],null,{\"statements\":[[0,\"\\n    \"],[14,3,[[28,\"hash\",null,[[\"item\"],[[23,0,[\"firstItem\"]]]]]]],[1,[28,\"t\",[\"list.two_item.delimiter\"],null],false],[14,3,[[28,\"hash\",null,[[\"item\"],[[23,0,[\"lastItem\"]]]]]]],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"gt\",[[23,0,[\"totalCount\"]],2],null]],null,{\"statements\":[[0,\"\\n    \"],[14,3,[[28,\"hash\",null,[[\"item\"],[[23,0,[\"firstItem\"]]]]]]],[1,[28,\"t\",[\"list.many_item.first_delimiter\"],null],false],[4,\"each\",[[23,0,[\"middleItems\"]]],null,{\"statements\":[[4,\"if\",[[23,2,[]]],null,{\"statements\":[[1,[28,\"t\",[\"list.many_item.each_delimiter\"],null],false]],\"parameters\":[]},null],[14,3,[[28,\"hash\",null,[[\"item\"],[[23,1,[]]]]]]]],\"parameters\":[1,2]},null],[1,[28,\"t\",[\"list.many_item.last_delimiter\"],null],false],[4,\"if\",[[23,0,[\"lastItem\"]]],null,{\"statements\":[[14,3,[[28,\"hash\",null,[[\"item\"],[[23,0,[\"lastItem\"]]]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[14,3,[[28,\"hash\",null,[[\"remainingCount\"],[[23,0,[\"remainingCount\"]]]]]]],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/inline-list/template.hbs"
    }
  });

  _exports.default = _default;
});