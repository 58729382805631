define("osf-components/components/subjects/browse/item-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jb1/ZK0R",
    "block": "{\"symbols\":[\"index\",\"itemManager\",\"@ItemManager\",\"@items\",\"&attrs\"],\"statements\":[[7,\"ul\",false],[12,\"class\",[29,[[28,\"local-class\",[\"ItemList\"],[[\"from\"],[\"osf-components/components/subjects/browse/item-list/styles\"]]]]]],[13,5],[8],[0,\"\\n\"],[4,\"each\",[[28,\"range\",[0,[23,0,[\"itemCount\"]]],null]],null,{\"statements\":[[0,\"        \"],[6,[23,3,[]],[],[[\"@subject\"],[[28,\"get\",[[23,4,[]],[23,1,[]]],null]]],{\"statements\":[[0,\"\\n            \"],[5,\"subjects/browse/item\",[],[[\"@singleSubjectManager\"],[[23,2,[]]]]],[0,\"\\n        \"]],\"parameters\":[2]}],[0,\"\\n\"]],\"parameters\":[1]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/subjects/browse/item-list/template.hbs"
    }
  });

  _exports.default = _default;
});