define("osf-components/components/editable-field/publication-doi-manager/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HgEbgEB8",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[28,\"hash\",null,[[\"save\",\"cancel\",\"fieldIsEmpty\",\"emptyFieldText\",\"startEditing\",\"inEditMode\",\"userCanEdit\",\"shouldShowField\",\"validationNode\",\"publicationDoi\",\"didValidate\",\"isSaving\",\"changeset\"],[[28,\"perform\",[[23,0,[\"save\"]]],null],[28,\"action\",[[23,0,[]],[23,0,[\"cancel\"]]],null],[23,0,[\"fieldIsEmpty\"]],[28,\"t\",[\"registries.registration_metadata.no_publication_doi\"],null],[28,\"action\",[[23,0,[]],[23,0,[\"startEditing\"]]],null],[23,0,[\"inEditMode\"]],[23,0,[\"userCanEdit\"]],[23,0,[\"shouldShowField\"]],[23,0,[\"validationNode\"]],[23,0,[\"node\",\"articleDoi\"]],[23,0,[\"didValidate\"]],[23,0,[\"save\",\"isRunning\"]],[23,0,[\"changeset\"]]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/editable-field/publication-doi-manager/template.hbs"
    }
  });

  _exports.default = _default;
});