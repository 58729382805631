define("osf-components/components/registries/review-form-renderer/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EcV3AwzU",
    "block": "{\"symbols\":[\"pageManager\",\"group\",\"@draftManager\",\"@node\"],\"statements\":[[1,[28,\"assert\",[\"Registries::ReviewFormRenderer requires a draftManager\",[23,3,[]]],null],false],[0,\"\\n\"],[1,[28,\"assert\",[\"Registries::ReviewFormRenderer requires a node\",[23,4,[]]],null],false],[0,\"\\n\"],[4,\"each\",[[23,3,[\"pageManagers\"]]],null,{\"statements\":[[4,\"each\",[[23,1,[\"schemaBlockGroups\"]]],null,{\"statements\":[[0,\"        \"],[5,\"registries/schema-block-group-renderer\",[],[[\"@schemaBlockGroup\",\"@renderStrategy\"],[[23,2,[]],[28,\"component\",[\"registries/schema-block-renderer/read-only/mapper\"],[[\"registrationResponses\",\"node\",\"changeset\",\"draftManager\",\"schemaBlockGroup\"],[[23,3,[\"draftRegistration\",\"registrationResponses\"]],[23,4,[]],[23,1,[\"changeset\"]],[23,3,[]],[23,2,[]]]]]]]],[0,\"\\n\"]],\"parameters\":[2]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/registries/review-form-renderer/template.hbs"
    }
  });

  _exports.default = _default;
});