define("osf-components/components/node-doi/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dUQfprRw",
    "block": "{\"symbols\":[\"@manager\"],\"statements\":[[5,\"osf-link\",[[12,\"data-analytics-name\",\"Registration DOI\"],[12,\"data-test-registration-doi\",\"\"]],[[\"@href\"],[[23,1,[\"nodeDoiUrl\"]]]],{\"statements\":[[0,\"\\n    \"],[1,[23,1,[\"nodeDoi\"]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/node-doi/template.hbs"
    }
  });

  _exports.default = _default;
});