define("@ember-intl/decorators/intl", ["exports", "@ember-decorators/utils/decorator", "ember-intl"], function (_exports, _decorator, _emberIntl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

  function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

  var _default = (0, _decorator.decoratorWithParams)(function intl(target, key, desc) {
    var dependentKeys = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : [];
    var value = desc.value;
    var initializer = desc.initializer;
    delete desc.value;
    delete desc.initializer;

    var cp = _emberIntl.intl.apply(void 0, _toConsumableArray(dependentKeys).concat([function (intl, // eslint-disable-line no-shadow
    propertyKey) {
      var fn = value || initializer.call(this);
      (false && !(typeof fn === 'function') && Ember.assert("@intl: You need to decorate a function, but you decorated '".concat(fn, "'."), typeof fn === 'function'));
      return fn.call(this, intl, propertyKey);
    }])); // @ts-ignore


    return cp(target, key, desc);
  });

  _exports.default = _default;
});