define("osf-components/components/subjects/search/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1rDaodw7",
    "block": "{\"symbols\":[\"index\",\"singleSubjectManager\",\"@subjectsManager\"],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[[28,\"local-class\",[\"SearchWrapper\"],[[\"from\"],[\"osf-components/components/subjects/search/styles\"]]]]]],[8],[0,\"\\n    \"],[5,\"fa-icon\",[[12,\"class\",[29,[[28,\"local-class\",[\"SearchIcon\"],[[\"from\"],[\"osf-components/components/subjects/search/styles\"]]]]]]],[[\"@icon\",\"@fixedWidth\"],[\"search\",true]]],[0,\"\\n    \"],[5,\"input\",[],[[\"@name\",\"@type\",\"@value\",\"@placeholder\",\"@key-up\"],[\"search\",\"text\",[23,0,[\"userQuery\"]],[28,\"t\",[\"osf-components.subjects.search.placeholder\"],null],[28,\"action\",[[23,0,[]],[28,\"perform\",[[23,0,[\"doSearch\"]]],null]],null]]]],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[4,\"if\",[[28,\"or\",[[23,0,[\"isLoading\"]],[23,0,[\"searchResults\"]]],null]],null,{\"statements\":[[0,\"    \"],[7,\"ol\",true],[11,\"class\",[29,[[28,\"local-class\",[\"SearchResults\"],[[\"from\"],[\"osf-components/components/subjects/search/styles\"]]]]]],[11,\"inert\",[23,0,[\"isLoading\"]]],[8],[0,\"\\n\"],[4,\"each\",[[28,\"range\",[0,[23,0,[\"resultCount\"]]],null]],null,{\"statements\":[[0,\"            \"],[5,\"subjects/manager/single\",[],[[\"@subject\",\"@subjectsManager\"],[[28,\"get\",[[23,0,[\"searchResults\"]],[23,1,[]]],null],[23,3,[]]]],{\"statements\":[[0,\"\\n                \"],[5,\"subjects/search/search-result\",[],[[\"@singleSubjectManager\"],[[23,2,[]]]]],[0,\"\\n            \"]],\"parameters\":[2]}],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[0,\"            \"],[1,[28,\"t\",[\"osf-components.subjects.search.no_results\"],null],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/subjects/search/template.hbs"
    }
  });

  _exports.default = _default;
});