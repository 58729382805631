define("osf-components/components/registries/schema-block-renderer/read-only/multi-select/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "List": "_List_n15biu",
    "ResponseValue": "_ResponseValue_n15biu _Element_t890a9",
    "NoResponse": "_NoResponse_n15biu _Element_t890a9",
    "ValidationErrors": "_ValidationErrors_n15biu _Element_t890a9"
  };
  _exports.default = _default;
});