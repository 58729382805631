define("osf-components/components/osf-header/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NAYQZRSZ",
    "block": "{\"symbols\":[\"ctx\",\"&default\"],\"statements\":[[4,\"let\",[[28,\"hash\",null,[[\"navbar\"],[[28,\"component\",[\"osf-navbar\"],null]]]]],null,{\"statements\":[[4,\"if\",[[25,2]],null,{\"statements\":[[0,\"        \"],[14,2,[[23,1,[]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[1,[23,1,[\"navbar\"]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1]},null],[0,\"\\n\"],[7,\"div\",true],[11,\"id\",[29,[[23,0,[\"secondaryNavbarId\"]]]]],[8],[9],[0,\"\\n\"],[5,\"maintenance-banner\",[],[[],[]]],[0,\"\\n\"],[5,\"status-banner\",[],[[],[]]],[0,\"\\n\"],[5,\"tos-consent-banner\",[],[[],[]]],[0,\"\\n\"],[5,\"banners/view-only-link\",[],[[],[]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/osf-header/template.hbs"
    }
  });

  _exports.default = _default;
});