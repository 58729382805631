define("osf-components/components/node-navbar/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "NodeNavbar__navbar-toggle": "_NodeNavbar__navbar-toggle_1ai61d",
    "NodeNavbar__navbar-buffer": "_NodeNavbar__navbar-buffer_1ai61d"
  };
  _exports.default = _default;
});