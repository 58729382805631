define("osf-components/components/registries/schema-block-renderer/editable/multi-select-input/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "schema-block-multi-select": "_schema-block-multi-select_hkjnn4"
  };
  _exports.default = _default;
});