define("osf-components/components/new-project-navigation-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/v9xPQbe",
    "block": "{\"symbols\":[\"modal\",\"@node\"],\"statements\":[[5,\"bs-modal\",[],[[\"@onHidden\"],[[23,0,[\"closeModal\"]]]],{\"statements\":[[0,\"\\n    \"],[6,[23,1,[\"body\"]],[[12,\"data-analytics-scope\",\"New project navigation modal\"]],[[],[]],{\"statements\":[[0,\"\\n        \"],[5,\"osf-button\",[[12,\"data-test-close-with-x\",\"\"],[12,\"aria-label\",[28,\"t\",[\"general.close\"],null]]],[[\"@class\",\"@onClick\"],[\"close\",[28,\"action\",[[23,0,[]],[23,0,[\"closeModal\"]]],[[\"reload\"],[true]]]]],{\"statements\":[[0,\"\\n            \"],[1,[28,\"fa-icon\",[\"times\"],[[\"size\"],[\"sm\"]]],false],[0,\"\\n        \"]],\"parameters\":[]}],[0,\"\\n        \"],[7,\"h4\",true],[10,\"data-test-modal-message\",\"\"],[10,\"class\",\"add-project-success text-success\"],[8],[0,\"\\n            \"],[1,[23,0,[\"title\"]],false],[0,\"\\n        \"],[9],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n    \"],[6,[23,1,[\"footer\"]],[[12,\"data-analytics-scope\",\"New project navigation modal footer\"]],[[],[]],{\"statements\":[[0,\"\\n        \"],[5,\"osf-button\",[[12,\"data-test-stay-here\",\"\"],[12,\"data-analytics-name\",\"stay_here\"]],[[\"@onClick\"],[[28,\"action\",[[23,0,[]],[23,0,[\"stayHere\"]]],[[\"reload\"],[true]]]]],{\"statements\":[[0,\"\\n            \"],[1,[28,\"t\",[\"new_project.stay_here\"],null],false],[0,\"\\n        \"]],\"parameters\":[]}],[0,\"\\n        \"],[5,\"osf-link\",[[12,\"data-test-go-to-new\",\"\"],[12,\"data-analytics-name\",\"go_to_new_project\"],[12,\"class\",\"btn btn-success\"]],[[\"@href\"],[[23,2,[\"links\",\"html\"]]]],{\"statements\":[[0,\"\\n            \"],[1,[28,\"t\",[\"move_to_project.go_to_project\"],null],false],[0,\"\\n        \"]],\"parameters\":[]}],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/new-project-navigation-modal/template.hbs"
    }
  });

  _exports.default = _default;
});