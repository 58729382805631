define("osf-components/components/files/list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7xpclZsV",
    "block": "{\"symbols\":[\"item\",\"@filesManager\",\"&attrs\",\"@items\"],\"statements\":[[7,\"div\",false],[12,\"class\",[29,[[28,\"local-class\",[\"list-container\"],[[\"from\"],[\"osf-components/components/files/list/styles\"]]]]]],[13,3],[8],[0,\"\\n\"],[4,\"each\",[[23,4,[]]],null,{\"statements\":[[0,\"        \"],[5,\"files/item\",[[12,\"data-test-file-row\",\"\"]],[[\"@item\",\"@filesManager\"],[[23,1,[]],[23,2,[]]]]],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[4,\"if\",[[23,2,[\"canEdit\"]]],null,{\"statements\":[[0,\"            \"],[7,\"div\",true],[11,\"class\",[29,[[28,\"local-class\",[\"placeholder\"],[[\"from\"],[\"osf-components/components/files/list/styles\"]]]]]],[10,\"data-test-no-files-placeholder\",\"\"],[8],[0,\"\\n                \"],[5,\"fa-icon\",[],[[\"@icon\",\"@size\"],[\"upload\",\"4\"]]],[0,\"\\n                \"],[1,[28,\"t\",[\"osf-components.files-widget.drag_drop_files\"],null],false],[0,\"\\n            \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/files/list/template.hbs"
    }
  });

  _exports.default = _default;
});