define("osf-components/components/node-category-picker/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LvSXZJkw",
    "block": "{\"symbols\":[\"category\",\"@manager\"],\"statements\":[[4,\"if\",[[23,2,[\"inEditMode\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"data-test-select-category\",\"\"],[8],[0,\"\\n        \"],[5,\"power-select\",[],[[\"@options\",\"@selected\",\"@onchange\",\"@disabled\"],[[23,0,[\"categories\"]],[23,2,[\"selectedCategory\"]],[28,\"action\",[[23,0,[]],[23,2,[\"onSelect\"]]],null],[23,2,[\"save\",\"isRunning\"]]]],{\"statements\":[[0,\"\\n            \"],[5,\"node-category\",[],[[\"@category\"],[[23,1,[]]]]],[0,\"\\n        \"]],\"parameters\":[1]}],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/node-category-picker/template.hbs"
    }
  });

  _exports.default = _default;
});