define('ember-content-placeholders/components/content-placeholders-list', ['exports', 'ember-content-placeholders/components/content-placeholders-base', 'ember-content-placeholders/templates/components/content-placeholders-list'], function (exports, _contentPlaceholdersBase, _contentPlaceholdersList) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _contentPlaceholdersBase.default.extend({
    layout: _contentPlaceholdersList.default,

    className: 'ember-content-placeholders-list',
    classNameBindings: ['className'],

    ordered: false,

    items: 4,
    itemsArray: Ember.computed('items', function () {
      return Array.apply(null, Array(Ember.get(this, 'items')));
    })
  });
});