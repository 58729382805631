define("osf-components/components/osf-logo/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "OsfLogo": "_OsfLogo_4qpnis",
    "Double": "_Double_4qpnis",
    "Animate": "_Animate_4qpnis",
    "rotateHalf": "_rotateHalf_4qpnis",
    "slideFade": "_slideFade_4qpnis"
  };
  _exports.default = _default;
});