define("osf-components/components/side-nav/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "SideNav": "_SideNav_dsexk7",
    "Collapsed": "_Collapsed_dsexk7",
    "Links": "_Links_dsexk7"
  };
  _exports.default = _default;
});