define("osf-components/components/copyable-text/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "CopyButton": "_CopyButton_1m006w",
    "CopyableInput": "_CopyableInput_1m006w"
  };
  _exports.default = _default;
});