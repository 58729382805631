define("osf-components/components/registries/schema-block-renderer/read-only/files/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "v0urMr4b",
    "block": "{\"symbols\":[\"file\",\"@changeset\",\"@schemaBlock\",\"&attrs\"],\"statements\":[[4,\"if\",[[23,0,[\"hasResponses\"]]],null,{\"statements\":[[0,\"    \"],[7,\"ul\",false],[12,\"class\",[29,[[28,\"local-class\",[\"FilesList\"],[[\"from\"],[\"osf-components/components/registries/schema-block-renderer/read-only/files/styles\"]]]]]],[12,\"data-test-read-only-file-widget\",\"\"],[13,4],[8],[0,\"\\n\"],[4,\"each\",[[23,0,[\"responses\"]]],null,{\"statements\":[[0,\"            \"],[7,\"li\",true],[8],[0,\"\\n\"],[4,\"if\",[[23,0,[\"isValidating\"]]],null,{\"statements\":[[1,[23,1,[\"name\"]],false]],\"parameters\":[]},{\"statements\":[[0,\"                    \"],[5,\"osf-link\",[[12,\"data-test-file-link\",[29,[[23,1,[\"id\"]]]]]],[[\"@href\",\"@target\"],[[23,1,[\"links\",\"html\"]],\"_blank\"]],{\"statements\":[[1,[23,1,[\"name\"]],false]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]}],[0,\"            \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"p\",true],[11,\"class\",[29,[[28,\"local-class\",[\"NoFilesSelected\"],[[\"from\"],[\"osf-components/components/registries/schema-block-renderer/read-only/files/styles\"]]]]]],[8],[0,\"\\n        \"],[1,[28,\"t\",[\"osf-components.registries.schema-block-renderer/read-only/files.noFilesSelected\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[4,\"if\",[[23,0,[\"changeset\"]]],null,{\"statements\":[[0,\"    \"],[5,\"validation-errors\",[[12,\"class\",[29,[[28,\"local-class\",[\"ValidationErrors\"],[[\"from\"],[\"osf-components/components/registries/schema-block-renderer/read-only/files/styles\"]]]]]]],[[\"@changeset\",\"@key\",\"@isValidating\"],[[23,2,[]],[23,3,[\"registrationResponseKey\"]],[23,0,[\"isValidating\"]]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/registries/schema-block-renderer/read-only/files/template.hbs"
    }
  });

  _exports.default = _default;
});