define("osf-components/components/registries/schema-block-renderer/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pnYjtCtP",
    "block": "{\"symbols\":[\"Mapper\",\"renderers\",\"Renderer\",\"@schemaBlock\",\"@optionBlocks\",\"@uniqueID\",\"@isRequired\",\"@isFieldsetGroup\",\"@renderStrategy\"],\"statements\":[[4,\"let\",[[23,9,[]]],null,{\"statements\":[[0,\"    \"],[6,[23,1,[]],[],[[],[]],{\"statements\":[[0,\"\\n\"],[4,\"let\",[[28,\"get\",[[23,2,[]],[23,4,[\"blockType\"]]],null]],null,{\"statements\":[[0,\"            \"],[6,[23,3,[]],[],[[\"@schemaBlock\",\"@optionBlocks\",\"@disabled\",\"@shouldShowMessages\",\"@uniqueID\",\"@isRequired\",\"@isFieldsetGroup\"],[[23,4,[]],[23,5,[]],[23,0,[\"disabled\"]],[23,0,[\"shouldShowMessages\"]],[23,6,[]],[23,7,[]],[23,8,[]]]]],[0,\"\\n\"]],\"parameters\":[3]},null],[0,\"    \"]],\"parameters\":[2]}],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/registries/schema-block-renderer/template.hbs"
    }
  });

  _exports.default = _default;
});