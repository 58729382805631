define("osf-components/components/gutters/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gUA9vVvD",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[5,\"container\",[[12,\"class\",[29,[[28,\"local-class\",[\"GutterContainer\"],[[\"from\"],[\"osf-components/components/gutters/styles\"]]]]]],[13,1]],[[],[]],{\"statements\":[[0,\"\\n    \"],[14,2,[[28,\"hash\",null,[[\"body\",\"left-gutter\",\"right-gutter\"],[[28,\"component\",[\"x-dummy\"],[[\"class\"],[[28,\"local-class\",[[23,0,[\"bodyClasses\"]]],[[\"from\"],[\"osf-components/components/gutters/styles\"]]]]]],[28,\"component\",[\"x-dummy\"],[[\"yieldValue\",\"data-gutter-mode\",\"data-gutter-closed\",\"class\"],[[28,\"hash\",null,[[\"mode\",\"closed\"],[[23,0,[\"leftMode\"]],[23,0,[\"leftClosed\"]]]]],[23,0,[\"leftMode\"]],[28,\"concat\",[[23,0,[\"leftClosed\"]]],null],[28,\"local-class\",[\"LeftGutter\"],[[\"from\"],[\"osf-components/components/gutters/styles\"]]]]]],[28,\"component\",[\"x-dummy\"],[[\"yieldValue\",\"data-gutter-mode\",\"data-gutter-closed\",\"class\"],[[28,\"hash\",null,[[\"mode\",\"closed\"],[[23,0,[\"rightMode\"]],[23,0,[\"rightClosed\"]]]]],[23,0,[\"rightMode\"]],[28,\"concat\",[[23,0,[\"rightClosed\"]]],null],[28,\"local-class\",[\"RightGutter\"],[[\"from\"],[\"osf-components/components/gutters/styles\"]]]]]]]]]]],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/gutters/template.hbs"
    }
  });

  _exports.default = _default;
});