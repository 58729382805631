define("osf-components/components/loading-indicator/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9qugSAaB",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[[28,\"concat\",[[28,\"if\",[[23,0,[\"inline\"]],\"ball-pulse\",\"ball-scale\"],null],\" \",[28,\"if\",[[23,0,[\"dark\"]],\"ball-dark\",\"ball-light\"],null]],null],\" \",[28,\"local-class\",[\"LoadingIndicator\"],[[\"from\"],[\"osf-components/components/loading-indicator/styles\"]]]]]],[8],[0,\"\\n\"],[4,\"if\",[[23,0,[\"inline\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\",true],[8],[9],[0,\"\\n        \"],[7,\"div\",true],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[7,\"div\",true],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/loading-indicator/template.hbs"
    }
  });

  _exports.default = _default;
});