define("osf-components/components/osf-navbar/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "NavBarBuffer": "_NavBarBuffer_xzf9qv",
    "NavbarToggleXS": "_NavbarToggleXS_xzf9qv",
    "PrimaryNav__toggle": "_PrimaryNav__toggle_xzf9qv",
    "osf-navbar-logo": "_osf-navbar-logo_xzf9qv",
    "secondary-navigation": "_secondary-navigation_xzf9qv",
    "links": "_links_xzf9qv"
  };
  _exports.default = _default;
});