define("osf-components/helpers/get-ancestor-descriptor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getAncestorDescriptor = getAncestorDescriptor;
  _exports.default = void 0;

  // TODO: intl-ize, this is probably better off as a component rather than a helper

  /**
   * Functions to format project titles the way they are displayed on the dashboard
   * Copied from ember-osf-preprints:
   * https://github.com/centerforopenscience/ember-osf-preprints/blob/develop/app/helpers/get-ancestor-descriptor.js
   * Amended for record-data-based ember-data.
   */
  function fetchIdFromRelationshipLink(node, relationship) {
    // If id is not embedded in request, Private node ids can be accessed under initializedRelationships.
    // May still return undefined if parent, for example, does not exist.
    if (node) {
      // @ts-ignore - private attribute
      var initializedRelationship = node._internalModel.__recordData._relationships.initializedRelationships[relationship];

      if (initializedRelationship && initializedRelationship.link) {
        return initializedRelationship.link.split('nodes')[1].replace(/\//g, '');
      }
    }

    return undefined;
  }

  function fetchTitle(node, relationship) {
    // Fetches parent or root title.  If null, marks 'Private'.
    var title = node.get(relationship).get('title');
    return typeof title !== 'undefined' ? title : 'Private';
  }

  function getAncestorDescriptor(params) {
    // Formats titles similar to the way they're displayed in the dashboard.
    // For example, Root Name / ... / Parent Name / Node Name.
    var node = params[0];
    var nodeId = node.id;
    var rootId = node.root.get('id');
    var parentId = node.parent.get('id');
    var parent = node.parent instanceof Ember.ObjectProxy ? node.get('parent').content : node.parent;
    var parentParentId = parent ? parent.parent && parent.parent.get('id') : undefined;
    var separator = ' / ';
    var ellipsis = "\u2026";

    if (typeof rootId === 'undefined') {
      rootId = fetchIdFromRelationshipLink(node, 'root');
    }

    if (typeof parentId === 'undefined') {
      parentId = fetchIdFromRelationshipLink(node, 'parent');
    }

    if (typeof parentParentId === 'undefined') {
      parentParentId = fetchIdFromRelationshipLink(parent, 'parent');
    }

    var parentTitle = fetchTitle(node, 'parent');
    var rootTitle = fetchTitle(node, 'root');
    var result = [];

    if (rootId === nodeId) {
      // One level
      return '';
    }

    if (rootId === parentId) {
      // Two levels
      result.push(parentTitle);
    } else if (rootId === parentParentId) {
      // Three levels
      result.push(rootTitle, parentTitle);
    } else if (parentParentId === undefined) {
      // Cannot deduce number of levels.
      result.push(ellipsis);
    } else {
      // Four + levels
      result.push(rootTitle, ellipsis, parentTitle);
    }

    return [].concat(result, ['']).join(separator);
  }

  var _default = Ember.Helper.helper(getAncestorDescriptor);

  _exports.default = _default;
});