define("osf-components/components/registries/partial-registration-modal/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "modal-title": "_modal-title_1y0u91",
    "select-clear-button": "_select-clear-button_1y0u91",
    "list": "_list_1y0u91"
  };
  _exports.default = _default;
});