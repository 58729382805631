define("osf-components/components/validated-input/checkboxes/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CZujqRY0",
    "block": "{\"symbols\":[\"option\",\"checkboxId\",\"&default\"],\"statements\":[[4,\"validated-input/x-input-wrapper\",null,[[\"model\",\"changeset\",\"errors\",\"valuePath\",\"validationStatus\"],[[23,0,[\"model\"]],[23,0,[\"changeset\"]],[23,0,[\"errors\"]],[23,0,[\"valuePath\"]],[23,0,[\"validationStatus\"]]]],{\"statements\":[[0,\"    \"],[7,\"div\",true],[11,\"class\",[29,[[28,\"local-class\",[\"Checkboxes\"],[[\"from\"],[\"osf-components/components/validated-input/checkboxes/styles\"]]]]]],[8],[0,\"\\n\"],[4,\"each\",[[23,0,[\"options\"]]],null,{\"statements\":[[4,\"let\",[[28,\"unique-id\",[[23,0,[\"elementId\"]]],null]],null,{\"statements\":[[0,\"                \"],[14,3,[[28,\"hash\",null,[[\"option\",\"checkboxId\",\"checkbox\"],[[23,1,[]],[23,2,[]],[28,\"component\",[\"validated-input/checkboxes/x-checkbox\"],[[\"relationArray\",\"option\",\"checkboxName\",\"ariaLabel\",\"disabled\",\"checkboxId\",\"onCheck\"],[[23,0,[\"value\"]],[23,1,[]],[23,0,[\"valuePath\"]],[23,0,[\"ariaLabel\"]],[23,0,[\"disabled\"]],[23,2,[]],[28,\"action\",[[23,0,[]],[23,0,[\"onCheck\"]]],null]]]]]]]]],[0,\"\\n\"]],\"parameters\":[2]},null]],\"parameters\":[1]},null],[0,\"    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/validated-input/checkboxes/template.hbs"
    }
  });

  _exports.default = _default;
});