define("osf-components/components/validated-input/x-input-wrapper/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CO9sqZnY",
    "block": "{\"symbols\":[\"@errors\",\"&default\"],\"statements\":[[4,\"if\",[[23,0,[\"label\"]]],null,{\"statements\":[[0,\"    \"],[7,\"label\",true],[11,\"for\",[29,[[23,0,[\"inputElementId\"]]]]],[8],[1,[23,0,[\"label\"]],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[14,2,[[23,0,[\"inputElementId\"]]]],[0,\"\\n\\n\"],[4,\"if\",[[28,\"or\",[[23,0,[\"hasError\"]],[23,0,[\"hasWarning\"]]],null]],null,{\"statements\":[[4,\"if\",[[23,0,[\"changeset\"]]],null,{\"statements\":[[0,\"        \"],[5,\"validation-errors\",[[12,\"class\",\"help-block\"]],[[\"@changeset\",\"@key\",\"@errors\"],[[23,0,[\"changeset\"]],[23,0,[\"valuePath\"]],[23,1,[]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[7,\"div\",true],[10,\"class\",\"help-block\"],[8],[0,\"\\n            \"],[1,[28,\"get\",[[28,\"get\",[[28,\"get\",[[28,\"get\",[[23,0,[\"model\"]],\"validations\"],null],\"attrs\"],null],[23,0,[\"valuePath\"]]],null],\"message\"],null],false],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/validated-input/x-input-wrapper/template.hbs"
    }
  });

  _exports.default = _default;
});