define("osf-components/components/responsive-dropdown/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6zgXIXWG",
    "block": "{\"symbols\":[\"dd\",\"@disabled\",\"@onClose\",\"@initiallyOpened\",\"&default\"],\"statements\":[[5,\"basic-dropdown\",[],[[\"@verticalPosition\",\"@horizontalPosition\",\"@calculatePosition\",\"@disabled\",\"@onClose\",\"@renderInPlace\",\"@initiallyOpened\"],[[23,0,[\"verticalPosition\"]],[23,0,[\"horizontalPosition\"]],[28,\"action\",[[23,0,[]],[23,0,[\"calculatePosition\"]]],null],[23,2,[]],[28,\"action\",[[23,0,[]],[23,0,[\"closeDropdown\"]],[23,3,[]]],null],[23,0,[\"shouldRenderInPlace\"]],[23,4,[]]]],{\"statements\":[[0,\"\\n    \"],[14,5,[[28,\"hash\",null,[[\"trigger\",\"isOpen\",\"uniqueId\",\"open\",\"close\",\"toggle\",\"content\"],[[28,\"component\",[[23,1,[\"trigger\"]]],[[\"class\"],[[28,\"local-class\",[\"Trigger\"],[[\"from\"],[\"osf-components/components/responsive-dropdown/styles\"]]]]]],[23,1,[\"isOpen\"]],[23,1,[\"uniqueId\"]],[28,\"action\",[[23,0,[]],[23,1,[\"actions\",\"open\"]]],null],[28,\"action\",[[23,0,[]],[23,1,[\"actions\",\"close\"]]],null],[28,\"action\",[[23,0,[]],[23,1,[\"actions\",\"toggle\"]]],null],[28,\"component\",[[23,1,[\"content\"]]],[[\"overlay\",\"defaultClass\"],[[23,0,[\"useOverlay\"]],[28,\"local-class\",[[28,\"if\",[[23,0,[\"useOverlay\"]],\"reg-modal-dialog\"],null]],[[\"from\"],[\"osf-components/components/responsive-dropdown/styles\"]]]]]]]]]]],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/responsive-dropdown/template.hbs"
    }
  });

  _exports.default = _default;
});