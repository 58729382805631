define("osf-components/components/status-banner/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "StatusBanner": "_StatusBanner_1vnszi",
    "StatusBanner__jumbo": "_StatusBanner__jumbo_1vnszi"
  };
  _exports.default = _default;
});