define("osf-components/components/contributors/manager/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0O+r1/A1",
    "block": "{\"symbols\":[\"@node\",\"&default\"],\"statements\":[[14,2,[[28,\"hash\",null,[[\"contributors\"],[[23,1,[\"contributors\"]]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/contributors/manager/template.hbs"
    }
  });

  _exports.default = _default;
});