define("osf-components/components/join-osf-banner/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "JoinOsfBanner": "_JoinOsfBanner_1hm5v5",
    "slideUp": "_slideUp_1hm5v5",
    "JoinOsfBanner--collapsed": "_JoinOsfBanner--collapsed_1hm5v5",
    "slideDown": "_slideDown_1hm5v5",
    "JoinOsfBanner__banner": "_JoinOsfBanner__banner_1hm5v5",
    "JoinOsfBanner__heading": "_JoinOsfBanner__heading_1hm5v5",
    "JoinOsfBanner__img": "_JoinOsfBanner__img_1hm5v5"
  };
  _exports.default = _default;
});