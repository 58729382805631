define("osf-components/components/validated-input/checkboxes/x-checkbox/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XX7vWLAW",
    "block": "{\"symbols\":[\"@option\",\"@onCheck\"],\"statements\":[[5,\"input\",[[12,\"aria-label\",[23,0,[\"ariaLabel\"]]]],[[\"@type\",\"@checked\",\"@id\",\"@name\",\"@disabled\",\"@change\"],[\"checkbox\",[23,0,[\"checked\"]],[23,0,[\"checkboxId\"]],[23,0,[\"checkboxName\"]],[23,0,[\"disabled\"]],[28,\"action\",[[23,0,[]],[23,2,[]],[23,1,[]]],null]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/validated-input/checkboxes/x-checkbox/template.hbs"
    }
  });

  _exports.default = _default;
});