define("osf-components/components/osf-layout/top/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7xVkwcGe",
    "block": "{\"symbols\":[\"@sidenavGutterClosed\",\"@toggleSidenav\",\"&default\"],\"statements\":[[14,3,[[28,\"hash\",null,[[\"toggleSidenav\",\"sidenavGutterClosed\"],[[28,\"action\",[[23,0,[]],[23,2,[]]],null],[23,1,[]]]]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/osf-layout/top/template.hbs"
    }
  });

  _exports.default = _default;
});