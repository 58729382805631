define("osf-components/components/registries/schema-block-renderer/label/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uNOcZp+X",
    "block": "{\"symbols\":[\"@uniqueID\",\"@schemaBlock\",\"&attrs\",\"@isRequired\",\"@readonly\",\"@isEditableForm\",\"@draftManager\",\"@isFieldsetGroup\"],\"statements\":[[4,\"if\",[[28,\"or\",[[23,2,[\"displayText\"]],[23,2,[\"helpText\"]],[23,2,[\"exampleText\"]]],null]],null,{\"statements\":[[4,\"if\",[[23,8,[]]],null,{\"statements\":[[0,\"        \"],[7,\"legend\",false],[12,\"class\",[29,[[28,\"local-class\",[\"Label\"],[[\"from\"],[\"osf-components/components/registries/schema-block-renderer/label/styles\"]]]]]],[12,\"data-test-question-label\",\"\"],[12,\"id\",[23,2,[\"elementId\"]]],[13,3],[8],[0,\"\\n            \"],[5,\"registries/schema-block-renderer/label/label-content\",[],[[\"@schemaBlock\",\"@isRequired\",\"@readonly\",\"@isEditableForm\",\"@draftManager\"],[[23,2,[]],[23,4,[]],[23,5,[]],[23,6,[]],[23,7,[]]]]],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[7,\"label\",false],[12,\"class\",[29,[[28,\"local-class\",[\"Label\"],[[\"from\"],[\"osf-components/components/registries/schema-block-renderer/label/styles\"]]]]]],[12,\"data-test-question-label\",\"\"],[12,\"for\",[23,1,[]]],[12,\"id\",[23,2,[\"elementId\"]]],[13,3],[8],[0,\"\\n            \"],[5,\"registries/schema-block-renderer/label/label-content\",[],[[\"@schemaBlock\",\"@isRequired\",\"@readonly\",\"@isEditableForm\",\"@draftManager\"],[[23,2,[]],[23,4,[]],[23,5,[]],[23,6,[]],[23,7,[]]]]],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/registries/schema-block-renderer/label/template.hbs"
    }
  });

  _exports.default = _default;
});