define("osf-components/components/password-strength-bar/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "create-password": "_create-password_x9ewxv",
    "warning-message": "_warning-message_x9ewxv"
  };
  _exports.default = _default;
});