define("osf-components/components/copyable-text/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "w4TDTeU+",
    "block": "{\"symbols\":[],\"statements\":[[7,\"span\",true],[10,\"class\",\"input-group-btn\"],[8],[0,\"\\n    \"],[5,\"copy-button\",[[12,\"class\",[29,[[28,\"local-class\",[\"CopyButton\"],[[\"from\"],[\"osf-components/components/copyable-text/styles\"]]]]]],[12,\"aria-label\",[28,\"t\",[\"osf-components.copyable-text.copyToClipboard\"],null]]],[[\"@clipboardText\",\"@success\",\"@error\",\"@disabled\",\"@class\"],[[23,0,[\"text\"]],[28,\"action\",[[23,0,[]],[23,0,[\"_success\"]]],null],[28,\"action\",[[23,0,[]],[23,0,[\"_error\"]]],null],[23,0,[\"disabled\"]],\"btn btn-default btn-sm\"]],{\"statements\":[[0,\"\\n        \"],[1,[28,\"fa-icon\",[\"copy\"],null],false],[0,\"\\n        \"],[5,\"bs-tooltip\",[],[[\"@triggerEvents\",\"@visible\"],[\"\",[23,0,[\"showTooltip\"]]]],{\"statements\":[[0,\"\\n            \"],[1,[28,\"t\",[\"osf-components.copyable-text.copied\"],null],false],[0,\"\\n        \"]],\"parameters\":[]}],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"input\",true],[11,\"class\",[29,[\"form-control \",[28,\"local-class\",[\"CopyableInput\"],[[\"from\"],[\"osf-components/components/copyable-text/styles\"]]]]]],[10,\"readonly\",\"true\"],[11,\"value\",[23,0,[\"text\"]]],[10,\"type\",\"text\"],[8],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/copyable-text/template.hbs"
    }
  });

  _exports.default = _default;
});