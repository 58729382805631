define("osf-components/components/carousel/x-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PoQVBF3p",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[7,\"li\",false],[12,\"class\",[29,[[28,\"local-class\",[[28,\"if\",[[23,0,[\"isActive\"]],\"active\"],null]],[[\"from\"],[\"osf-components/components/carousel/x-item/styles\"]]]]]],[13,1],[8],[14,2],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/carousel/x-item/template.hbs"
    }
  });

  _exports.default = _default;
});