define("osf-components/components/osf-layout/registries-side-nav/icon/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "Icon": "_Icon_hvfztr"
  };
  _exports.default = _default;
});