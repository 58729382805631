define("osf-components/components/get-started-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jWgrWtiX",
    "block": "{\"symbols\":[\"&attrs\"],\"statements\":[[5,\"osf-link\",[[12,\"class\",[29,[\"btn btn-primary \",[28,\"local-class\",[\"startButton\"],[[\"from\"],[\"osf-components/components/get-started-button/styles\"]]]]]],[12,\"data-test-get-started-button\",\"\"],[12,\"data-analytics-name\",\"Get started button\"],[13,1]],[[\"@route\"],[\"register\"]],{\"statements\":[[0,\"\\n    \"],[1,[28,\"t\",[\"home.free_link\"],null],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/get-started-button/template.hbs"
    }
  });

  _exports.default = _default;
});