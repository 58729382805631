define("osf-components/components/registries/schema-block-renderer/paragraph/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PxPreZ3b",
    "block": "{\"symbols\":[\"&attrs\",\"@schemaBlock\"],\"statements\":[[7,\"p\",false],[12,\"data-test-paragraph-section\",\"\"],[13,1],[8],[0,\"\\n    \"],[1,[23,2,[\"displayText\"]],false],[0,\"\\n    \"],[7,\"span\",true],[8],[0,\"\\n\"],[4,\"if\",[[23,0,[\"isEditableForm\"]]],null,{\"statements\":[[0,\"            \"],[5,\"registries/schema-block-renderer/helper-text-icon\",[],[[\"@helpText\"],[[23,0,[\"schemaBlock\",\"helpText\"]]]]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/registries/schema-block-renderer/paragraph/template.hbs"
    }
  });

  _exports.default = _default;
});