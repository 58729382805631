define("osf-components/components/form-controls/wrapper/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "czxX8DhN",
    "block": "{\"symbols\":[\"inputElementId\",\"@changeset\",\"@valuePath\",\"&default\",\"@shouldShowMessages\"],\"statements\":[[4,\"let\",[[28,\"unique-id\",null,null]],null,{\"statements\":[[4,\"if\",[[23,0,[\"label\"]]],null,{\"statements\":[[0,\"        \"],[7,\"label\",true],[11,\"for\",[29,[[23,1,[]]]]],[8],[1,[23,0,[\"label\"]],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[14,4,[[23,1,[]]]],[0,\"\\n\"],[4,\"if\",[[23,5,[]]],null,{\"statements\":[[0,\"        \"],[5,\"validation-errors\",[],[[\"@changeset\",\"@key\"],[[23,2,[]],[23,3,[]]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/form-controls/wrapper/template.hbs"
    }
  });

  _exports.default = _default;
});