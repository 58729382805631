define("osf-components/components/bs-alert/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4UoameAS",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"unless\",[[23,0,[\"hidden\"]]],null,{\"statements\":[[4,\"if\",[[23,0,[\"dismissible\"]]],null,{\"statements\":[[0,\"        \"],[7,\"button\",false],[12,\"class\",\"close\"],[12,\"aria-label\",[28,\"t\",[\"general.close\"],null]],[12,\"type\",\"button\"],[3,\"on\",[\"click\",[23,0,[\"dismiss\"]]]],[8],[0,\"\\n            \"],[7,\"span\",true],[10,\"aria-hidden\",\"true\"],[8],[1,[28,\"fa-icon\",[\"times\"],null],false],[9],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/bs-alert/template.hbs"
    }
  });

  _exports.default = _default;
});