define("osf-components/components/license-text/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+7TX0BO3",
    "block": "{\"symbols\":[\"&attrs\"],\"statements\":[[7,\"pre\",false],[12,\"class\",[29,[[28,\"local-class\",[\"LicenseText\"],[[\"from\"],[\"osf-components/components/license-text/styles\"]]]]]],[13,1],[8],[1,[23,0,[\"licenseText\"]],false],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/license-text/template.hbs"
    }
  });

  _exports.default = _default;
});