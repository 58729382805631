define("osf-components/components/file-browser-item/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "file-browser-item": "_file-browser-item_1v8xgw",
    "selected": "_selected_1v8xgw",
    "link-url": "_link-url_1v8xgw",
    "file-row-col": "_file-row-col_1v8xgw",
    "file-row-item": "_file-row-item_1v8xgw",
    "filename-link": "_filename-link_1v8xgw",
    "version-link": "_version-link_1v8xgw",
    "flash-message-alert": "_flash-message-alert_1v8xgw"
  };
  _exports.default = _default;
});