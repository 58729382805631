define("osf-components/components/node-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ucqhN4QQ",
    "block": "{\"symbols\":[\"list\",\"node\",\"@modelTaskInstance\",\"@relationshipName\",\"@showTags\",\"@disableNodeLink\",\"@showExportCsvLink\",\"@metadataSchema\",\"@showStatus\",\"&default\"],\"statements\":[[5,\"paginated-list/has-many\",[],[[\"@modelTaskInstance\",\"@relationshipName\",\"@query\",\"@bindReload\"],[[23,3,[]],[23,4,[]],[23,0,[\"queryParams\"]],[23,0,[\"bindReload\"]]]],{\"statements\":[[0,\"\\n    \"],[6,[23,1,[\"item\"]],[],[[],[]],{\"statements\":[[0,\"\\n        \"],[5,\"node-card\",[],[[\"@node\",\"@showTags\",\"@disableNodeLink\",\"@showExportCsvLink\",\"@metadataSchema\",\"@showStatus\"],[[23,2,[]],[23,5,[]],[23,6,[]],[23,7,[]],[23,8,[]],[23,9,[]]]]],[0,\"\\n    \"]],\"parameters\":[2]}],[0,\"\\n\\n    \"],[14,10,[[28,\"hash\",null,[[\"empty\"],[[23,1,[\"empty\"]]]]]]],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/node-list/template.hbs"
    }
  });

  _exports.default = _default;
});