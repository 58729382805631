define("osf-components/components/registries/schema-block-renderer/read-only-contributor-list/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "ContributorList": "_ContributorList_112o2h _Element_t890a9",
    "EditContributors": "_EditContributors_112o2h _Element_t890a9"
  };
  _exports.default = _default;
});