define("osf-components/components/registries/finalize-registration-modal/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "notice": "_notice_1nlide",
    "create-doi-option": "_create-doi-option_1nlide"
  };
  _exports.default = _default;
});