define("osf-components/components/files/menu/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "actions-menu-trigger": "_actions-menu-trigger_h7qy81",
    "trigger-button": "_trigger-button_h7qy81",
    "close-button": "_close-button_h7qy81",
    "actions-menu-content": "_actions-menu-content_h7qy81",
    "menu-button": "_menu-button_h7qy81"
  };
  _exports.default = _default;
});