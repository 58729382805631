define("ember-oo-modifiers/-private/create-manager", ["exports", "ember-oo-modifiers/-private/modifier-manager"], function (_exports, _modifierManager) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = createManager;
  var MANAGERS = new WeakMap();

  function createManager(owner) {
    var manager = MANAGERS.get(owner);

    if (manager === undefined) {
      manager = new _modifierManager.default(owner);
    }

    return manager;
  }
});