define("osf-components/components/gutters/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "GutterContainer": "_GutterContainer_cj6za6",
    "GutterBody": "_GutterBody_cj6za6",
    "SlideRight": "_SlideRight_cj6za6",
    "SlideLeft": "_SlideLeft_cj6za6",
    "LeftGutter": "_LeftGutter_cj6za6",
    "RightGutter": "_RightGutter_cj6za6"
  };
  _exports.default = _default;
});