define("osf-components/components/sign-up-policy/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "S7ctU7CV",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"t\",[\"osf-components.sign-up-policy.paragraph\"],[[\"link1\",\"link2\",\"htmlSafe\"],[[23,0,[\"termsLink\"]],[23,0,[\"privacyPolicyLink\"]],true]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/sign-up-policy/template.hbs"
    }
  });

  _exports.default = _default;
});