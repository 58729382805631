define("osf-components/components/editable-field/license-manager/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1bgw495M",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[28,\"hash\",null,[[\"save\",\"cancel\",\"changeLicense\",\"fieldIsEmpty\",\"emptyFieldText\",\"startEditing\",\"inEditMode\",\"userCanEdit\",\"shouldShowField\",\"registration\",\"requiredFields\",\"selectedLicense\",\"licensesAcceptable\",\"changeset\",\"updateNodeLicense\"],[[23,0,[\"save\"]],[23,0,[\"cancel\"]],[23,0,[\"changeLicense\"]],[23,0,[\"fieldIsEmpty\"]],[28,\"t\",[\"registries.registration_metadata.no_license\"],null],[23,0,[\"startEditing\"]],[23,0,[\"inEditMode\"]],[23,0,[\"userCanEdit\"]],[23,0,[\"shouldShowField\"]],[23,0,[\"node\"]],[23,0,[\"requiredFields\"]],[23,0,[\"selectedLicense\"]],[23,0,[\"licensesAcceptable\"]],[23,0,[\"changeset\"]],[23,0,[\"updateNodeLicense\"]]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/editable-field/license-manager/template.hbs"
    }
  });

  _exports.default = _default;
});