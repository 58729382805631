define("osf-components/components/password-strength-bar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bZkCjW7H",
    "block": "{\"symbols\":[\"@shouldShowMessages\"],\"statements\":[[4,\"if\",[[28,\"and\",[[23,1,[]],[23,0,[\"message\"]]],null]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[11,\"class\",[29,[[28,\"concat\",[[28,\"if\",[[23,0,[\"valid\"]],\"text-warning\",\"text-danger\"],null],\" col-xs-12\"],null],\" \",[28,\"local-class\",[\"warning-message\"],[[\"from\"],[\"osf-components/components/password-strength-bar/styles\"]]]]]],[8],[0,\"\\n        \"],[1,[23,0,[\"message\"]],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[7,\"div\",true],[10,\"data-test-password-bar\",\"\"],[10,\"class\",\"row\"],[11,\"hidden\",[28,\"not\",[[23,0,[\"progress\"]]],null]],[8],[0,\"\\n    \"],[7,\"div\",true],[11,\"class\",[29,[\"col-xs-12 \",[28,\"local-class\",[\"bar-container\"],[[\"from\"],[\"osf-components/components/password-strength-bar/styles\"]]]]]],[8],[0,\"\\n        \"],[7,\"div\",true],[11,\"class\",[29,[\"progress col-xs-12 \",[28,\"local-class\",[\"create-password\"],[[\"from\"],[\"osf-components/components/password-strength-bar/styles\"]]]]]],[8],[0,\"\\n            \"],[7,\"div\",true],[10,\"data-test-password-color-bar\",\"\"],[11,\"class\",[29,[\"progress-bar progress-bar-sm progress-bar-\",[23,0,[\"progress\"]],\" progress-bar-\",[23,0,[\"progressStyle\"]]]]],[10,\"role\",\"progressbar\"],[8],[0,\"\\n            \"],[9],[0,\"\\n        \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/password-strength-bar/template.hbs"
    }
  });

  _exports.default = _default;
});