define("osf-components/components/quickfile-nav/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HrmG9GDQ",
    "block": "{\"symbols\":[\"@user\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"container\"],[8],[0,\"\\n\"],[4,\"if\",[[23,1,[]]],null,{\"statements\":[[0,\"        \"],[7,\"h3\",true],[8],[0,\"\\n            \"],[5,\"osf-link\",[[12,\"data-analytics-name\",\"Go to quickfiles\"]],[[\"@route\",\"@models\"],[\"guid-user.quickfiles\",[28,\"array\",[[23,1,[\"id\"]]],null]]],{\"statements\":[[0,\"\\n                \"],[1,[28,\"t\",[\"quickfiles.title\"],[[\"user-name\"],[[23,1,[\"fullName\"]]]]],false],[0,\"\\n            \"]],\"parameters\":[]}],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/quickfile-nav/template.hbs"
    }
  });

  _exports.default = _default;
});