define("osf-components/components/institutions-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MOln4wVm",
    "block": "{\"symbols\":[\"list\",\"institution\",\"placeholder\",\"@manager\"],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[[28,\"local-class\",[\"paginatedInstitutions\"],[[\"from\"],[\"osf-components/components/institutions-list/styles\"]]]]]],[10,\"data-test-institutions-list\",\"\"],[8],[0,\"\\n\"],[4,\"paginated-list/has-many\",null,[[\"model\",\"relationshipName\",\"bindReload\",\"analyticsScope\",\"usePlaceholders\"],[[23,4,[\"node\"]],\"affiliatedInstitutions\",[23,4,[\"bindReload\"]],\"Project Institutions\",[23,0,[\"usePlaceholders\"]]]],{\"statements\":[[0,\"        \"],[6,[23,1,[\"item\"]],[],[[],[]],{\"statements\":[[0,\"\\n            \"],[7,\"div\",true],[11,\"class\",[29,[[28,\"local-class\",[\"InstitutionList__item\"],[[\"from\"],[\"osf-components/components/institutions-list/styles\"]]]]]],[8],[0,\"\\n\"],[4,\"if\",[[23,2,[]]],null,{\"statements\":[[0,\"                    \"],[5,\"osf-link\",[],[[\"@href\"],[[23,2,[\"links\",\"html\"]]]],{\"statements\":[[0,\"\\n                        \"],[7,\"img\",true],[11,\"data-test-institution-list-institution\",[23,2,[\"id\"]]],[10,\"class\",\"img-circle img-responsive\"],[11,\"src\",[23,2,[\"logoRoundedUrl\"]]],[11,\"alt\",[23,2,[\"name\"]]],[8],[9],[0,\"\\n                    \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"                    \"],[5,\"osf-placeholder\",[],[[],[]],{\"statements\":[[0,\"\\n                        \"],[6,[23,3,[\"circle\"]],[],[[],[]]],[0,\"\\n                    \"]],\"parameters\":[3]}],[0,\"\\n\"]],\"parameters\":[]}],[0,\"            \"],[9],[0,\"\\n        \"]],\"parameters\":[2]}],[0,\"\\n\"]],\"parameters\":[1]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/institutions-list/template.hbs"
    }
  });

  _exports.default = _default;
});