define("osf-components/components/zoom-to-route/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yT8HW0qx",
    "block": "{\"symbols\":[\"form\",\"param\",\"name\"],\"statements\":[[5,\"bs-form\",[],[[\"@model\",\"@onSubmit\"],[[23,0,[\"routeArgs\"]],[28,\"action\",[[23,0,[]],\"zoom\"],null]]],{\"statements\":[[0,\"\\n    \"],[5,\"power-select\",[],[[\"@options\",\"@selected\",\"@onchange\",\"@placeholder\"],[[23,0,[\"routeNames\"]],[23,0,[\"targetRoute\"]],[28,\"action\",[[23,0,[]],\"selectRoute\"],null],[28,\"t\",[\"dev_tools.zoom_to_route.placeholder\"],null]]],{\"statements\":[[0,\"\\n        \"],[1,[23,3,[]],false],[0,\"\\n    \"]],\"parameters\":[3]}],[0,\"\\n\"],[4,\"if\",[[23,0,[\"targetRoute\"]]],null,{\"statements\":[[0,\"        \"],[7,\"hr\",true],[8],[9],[0,\"\\n\"],[4,\"each\",[[23,0,[\"routeParams\"]]],null,{\"statements\":[[0,\"            \"],[6,[23,1,[\"element\"]],[],[[\"@controlType\",\"@label\",\"@placeholder\",\"@property\"],[\"text\",[23,2,[]],[23,2,[]],[23,2,[]]]]],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"        \"],[7,\"hr\",true],[8],[9],[0,\"\\n        \"],[5,\"osf-button\",[],[[\"@buttonType\"],[\"submit\"]],{\"statements\":[[0,\"\\n            \"],[1,[28,\"t\",[\"dev_tools.zoom_to_route.zoom\"],null],false],[0,\"\\n        \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/zoom-to-route/template.hbs"
    }
  });

  _exports.default = _default;
});