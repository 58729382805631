define("osf-components/components/editable-field/category-manager/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Od/D4iud",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[28,\"hash\",null,[[\"save\",\"cancel\",\"onSelect\",\"fieldIsEmpty\",\"startEditing\",\"inEditMode\",\"userCanEdit\",\"shouldShowField\",\"selectedCategory\",\"category\",\"isSaving\"],[[28,\"perform\",[[23,0,[\"save\"]]],null],[28,\"action\",[[23,0,[]],[23,0,[\"cancel\"]]],null],[28,\"action\",[[23,0,[]],[23,0,[\"onSelect\"]]],null],[23,0,[\"fieldIsEmpty\"]],[28,\"action\",[[23,0,[]],[23,0,[\"startEditing\"]]],null],[23,0,[\"inEditMode\"]],[23,0,[\"userCanEdit\"]],[23,0,[\"shouldShowField\"]],[23,0,[\"selectedCategory\"]],[23,0,[\"category\"]],[23,0,[\"save\",\"isRunning\"]]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/editable-field/category-manager/template.hbs"
    }
  });

  _exports.default = _default;
});