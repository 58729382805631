define("osf-components/components/validated-input/recaptcha/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2UzBEzZv",
    "block": "{\"symbols\":[],\"statements\":[[4,\"validated-input/x-input-wrapper\",null,[[\"model\",\"changeset\",\"errors\",\"valuePath\",\"validationStatus\"],[[23,0,[\"model\"]],[23,0,[\"changeset\"]],[23,0,[\"errors\"]],[23,0,[\"valuePath\"]],[23,0,[\"validationStatus\"]]]],{\"statements\":[[4,\"unless\",[[23,0,[\"isTesting\"]]],null,{\"statements\":[[0,\"        \"],[1,[28,\"g-recaptcha\",null,[[\"onSuccess\",\"onExpired\",\"disabled\",\"ref\"],[[28,\"action\",[[23,0,[]],\"onCaptchaResolved\"],null],[28,\"action\",[[23,0,[]],\"onCaptchaExpired\"],null],[23,0,[\"disabled\"]],[28,\"mut\",[[23,0,[\"gRecaptcha\"]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/validated-input/recaptcha/template.hbs"
    }
  });

  _exports.default = _default;
});