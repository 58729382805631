define("osf-components/components/registries/schema-block-renderer/editable/base/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "v5QSN5X8",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[[28,\"local-class\",[\"input-flexbox\"],[[\"from\"],[\"osf-components/components/registries/schema-block-renderer/editable/base/styles\"]]]]]],[8],[0,\"\\n    \"],[7,\"div\",true],[11,\"class\",[29,[[28,\"local-class\",[\"input-box\"],[[\"from\"],[\"osf-components/components/registries/schema-block-renderer/editable/base/styles\"]]]]]],[8],[0,\"\\n        \"],[14,1],[0,\"\\n    \"],[9],[0,\"\\n    \"],[5,\"registries/schema-block-renderer/helper-text-icon\",[],[[\"@helpText\"],[[23,0,[\"helpText\"]]]]],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/registries/schema-block-renderer/editable/base/template.hbs"
    }
  });

  _exports.default = _default;
});