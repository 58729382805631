define("osf-components/components/files/manager/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9qEKD+sJ",
    "block": "{\"symbols\":[\"@onSelectFile\",\"&default\"],\"statements\":[[14,2,[[28,\"hash\",null,[[\"loading\",\"loadingFolderItems\",\"loadingMore\",\"canEdit\",\"hasMore\",\"sort\",\"currentFolder\",\"displayedItems\",\"rootFolder\",\"inRootFolder\",\"onSelectFile\",\"fileProvider\",\"goToFolder\",\"goToParentFolder\",\"addFile\",\"loadMore\",\"sortItems\"],[[23,0,[\"loading\"]],[23,0,[\"loadingFolderItems\"]],[23,0,[\"loadingMore\"]],[23,0,[\"canEdit\"]],[23,0,[\"hasMore\"]],[23,0,[\"sort\"]],[23,0,[\"currentFolder\"]],[23,0,[\"displayedItems\"]],[23,0,[\"rootFolder\"]],[23,0,[\"inRootFolder\"]],[23,1,[]],[23,0,[\"fileProvider\"]],[28,\"action\",[[23,0,[]],[23,0,[\"goToFolder\"]]],null],[28,\"action\",[[23,0,[]],[23,0,[\"goToParentFolder\"]]],null],[28,\"perform\",[[23,0,[\"addFile\"]]],null],[28,\"perform\",[[23,0,[\"loadMore\"]]],null],[28,\"action\",[[23,0,[]],[23,0,[\"sortItems\"]]],null]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/files/manager/template.hbs"
    }
  });

  _exports.default = _default;
});