define("osf-components/components/osf-layout/registries-side-nav/label/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "LabelWrapper": "_LabelWrapper_b8wb9u",
    "Label": "_Label_b8wb9u",
    "Count": "_Count_b8wb9u"
  };
  _exports.default = _default;
});