define("osf-components/components/success-icon/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "AnimatedIcon": "_AnimatedIcon_x8ix3h",
    "AnimatedIcon--success": "_AnimatedIcon--success_x8ix3h",
    "AnimatedIcon__ring": "_AnimatedIcon__ring_x8ix3h",
    "AnimatedIcon__fix": "_AnimatedIcon__fix_x8ix3h",
    "AnimatedIcon__line": "_AnimatedIcon__line_x8ix3h",
    "AnimatedIcon__line-tip": "_AnimatedIcon__line-tip_x8ix3h",
    "animate-success-tip": "_animate-success-tip_x8ix3h",
    "AnimatedIcon__line-long": "_AnimatedIcon__line-long_x8ix3h",
    "animate-success-long": "_animate-success-long_x8ix3h",
    "AnimatedIcon__circular-line": "_AnimatedIcon__circular-line_x8ix3h",
    "AnimatedIcon__circular-line-left": "_AnimatedIcon__circular-line-left_x8ix3h",
    "AnimatedIcon__circular-line-right": "_AnimatedIcon__circular-line-right_x8ix3h",
    "rotate-placeholder": "_rotate-placeholder_x8ix3h"
  };
  _exports.default = _default;
});