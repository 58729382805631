define("osf-components/components/cookie-banner/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HlfmrWBc",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,0,[\"showBanner\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[11,\"class\",[29,[[28,\"local-class\",[\"CookieBanner\"],[[\"from\"],[\"osf-components/components/cookie-banner/styles\"]]]]]],[8],[0,\"\\n        \"],[7,\"div\",true],[11,\"class\",[29,[[28,\"local-class\",[\"Warning\"],[[\"from\"],[\"osf-components/components/cookie-banner/styles\"]]]]]],[8],[0,\"\\n            \"],[1,[28,\"t\",[\"cookieBanner.cookieWarning\"],[[\"htmlSafe\",\"termsLink\",\"privacyPolicyLink\"],[true,[23,0,[\"termsLink\"]],[23,0,[\"privacyPolicyLink\"]]]]],false],[0,\"\\n        \"],[9],[0,\"\\n        \"],[7,\"div\",true],[11,\"class\",[29,[[28,\"local-class\",[\"Accept\"],[[\"from\"],[\"osf-components/components/cookie-banner/styles\"]]]]]],[8],[0,\"\\n            \"],[5,\"osf-button\",[],[[\"@onClick\"],[[28,\"action\",[[23,0,[]],[23,0,[\"accept\"]]],null]]],{\"statements\":[[0,\"\\n                \"],[1,[28,\"t\",[\"cookieBanner.accept\"],null],false],[0,\"\\n            \"]],\"parameters\":[]}],[0,\"\\n        \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/cookie-banner/template.hbs"
    }
  });

  _exports.default = _default;
});