define("osf-components/components/node-navbar/link/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "K+MqHKPy",
    "block": "{\"symbols\":[\"&default\",\"@node\",\"@destination\"],\"statements\":[[4,\"if\",[[23,0,[\"useLinkTo\"]]],null,{\"statements\":[[0,\"    \"],[5,\"osf-link\",[[12,\"data-test-node-navbar-link\",[28,\"or\",[[23,3,[]],[23,2,[\"id\"]]],null]],[12,\"class\",[23,0,[\"extraClasses\"]]]],[[\"@route\",\"@models\"],[[23,0,[\"routeName\"]],[28,\"array\",[[23,2,[\"id\"]]],null]]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"            \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"            \"],[1,[28,\"t\",[[23,0,[\"translatingKey\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[5,\"osf-link\",[[12,\"data-test-node-navbar-link\",[28,\"or\",[[23,3,[]],[23,2,[\"id\"]]],null]],[12,\"class\",[23,0,[\"extraClasses\"]]]],[[\"@href\"],[[29,[\"/\",[23,2,[\"id\"]],\"/\",[23,3,[]]]]]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"            \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"            \"],[1,[28,\"t\",[[23,0,[\"translatingKey\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/node-navbar/link/template.hbs"
    }
  });

  _exports.default = _default;
});