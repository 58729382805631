define("osf-components/components/banners/view-only-link/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "Alert": "_Alert_1acc8u",
    "Banner": "_Banner_1acc8u"
  };
  _exports.default = _default;
});