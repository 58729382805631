define("osf-components/components/paginated-list/has-many/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hHH0O/VC",
    "block": "{\"symbols\":[\"list\",\"&default\"],\"statements\":[[4,\"paginated-list/layout\",null,[[\"isTable\",\"items\",\"page\",\"pageSize\",\"totalCount\",\"loading\",\"errorShown\",\"next\",\"previous\",\"doReload\"],[[23,0,[\"isTable\"]],[23,0,[\"items\"]],[23,0,[\"page\"]],[23,0,[\"pageSize\"]],[23,0,[\"totalCount\"]],[23,0,[\"loadItemsWrapperTask\",\"isRunning\"]],[23,0,[\"errorShown\"]],[28,\"action\",[[23,0,[]],[23,0,[\"next\"]]],null],[28,\"action\",[[23,0,[]],[23,0,[\"previous\"]]],null],[28,\"action\",[[23,0,[]],[23,0,[\"_doReload\"]]],null]]],{\"statements\":[[0,\"    \"],[14,2,[[23,1,[]]]],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/paginated-list/has-many/template.hbs"
    }
  });

  _exports.default = _default;
});