define("osf-components/components/registries/schema-block-renderer/read-only-contributor-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dUoHjNZh",
    "block": "{\"symbols\":[\"@node\",\"&attrs\"],\"statements\":[[7,\"div\",false],[12,\"class\",[29,[[28,\"local-class\",[\"ContributorList\"],[[\"from\"],[\"osf-components/components/registries/schema-block-renderer/read-only-contributor-list/styles\"]]]]]],[12,\"data-test-read-only-contributors-list\",\"\"],[13,2],[8],[0,\"\\n    \"],[5,\"contributor-list\",[],[[\"@node\",\"@shouldTruncate\",\"@shouldLinkUsers\"],[[23,1,[]],false,true]]],[0,\"\\n\"],[4,\"if\",[[23,0,[\"isEditableForm\"]]],null,{\"statements\":[[0,\"        \"],[5,\"registries/schema-block-renderer/helper-text-icon\",[],[[\"@helpText\"],[[23,0,[\"schemaBlock\",\"helpText\"]]]]],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"],[7,\"div\",true],[11,\"class\",[29,[[28,\"local-class\",[\"EditContributors\"],[[\"from\"],[\"osf-components/components/registries/schema-block-renderer/read-only-contributor-list/styles\"]]]]]],[10,\"data-test-edit-contributors\",\"\"],[8],[0,\"\\n\"],[4,\"unless\",[[23,1,[\"isRegistration\"]]],null,{\"statements\":[[0,\"        \"],[5,\"osf-link\",[[12,\"data-test-to-contributors-page\",\"\"]],[[\"@href\"],[[28,\"concat\",[[23,0,[\"osfUrl\"]],[23,1,[\"id\"]],\"/contributors/\"],null]]],{\"statements\":[[0,\"\\n            \"],[1,[28,\"t\",[\"osf-components.registries.read-only-contributors-list.editContributorsOnYourProject\"],null],false],[0,\"\\n        \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/registries/schema-block-renderer/read-only-contributor-list/template.hbs"
    }
  });

  _exports.default = _default;
});