define("osf-components/components/node-description-editable/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "28yeA33+",
    "block": "{\"symbols\":[\"@manager\"],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[[28,\"local-class\",[\"EditDescription\"],[[\"from\"],[\"osf-components/components/node-description-editable/styles\"]]]]]],[10,\"data-test-description-input\",\"\"],[8],[0,\"\\n    \"],[1,[28,\"textarea\",null,[[\"class\",\"autofocus\",\"value\",\"disabled\",\"rows\"],[\"form-control\",\"true\",[23,1,[\"currentDescription\"]],[23,1,[\"save\",\"isRunning\"]],5]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/node-description-editable/template.hbs"
    }
  });

  _exports.default = _default;
});