define("osf-components/components/files/upload-zone/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Yd8Vh6OB",
    "block": "{\"symbols\":[\"dropzoneId\",\"menu\",\"@filesManager\",\"&default\"],\"statements\":[[4,\"let\",[[28,\"unique-id\",[\"dropzone-widget\"],null]],null,{\"statements\":[[0,\"    \"],[5,\"files/menu\",[],[[\"@filesManager\",\"@setButtonClass\",\"@isUploading\"],[[23,3,[]],[28,\"action\",[[23,0,[]],[23,0,[\"setButtonClass\"]]],null],[23,0,[\"isUploading\"]]]],{\"statements\":[[0,\"\\n        \"],[5,\"dropzone-widget\",[],[[\"@options\",\"@dropzone\",\"@enable\",\"@id\",\"@clickable\",\"@uploadprogress\",\"@success\",\"@preUpload\",\"@error\",\"@buildUrl\",\"@addedfile\",\"@dragenter\",\"@dragover\",\"@drop\",\"@dragleave\"],[[23,0,[\"dropzoneOptions\"]],false,[23,0,[\"canEdit\"]],[23,1,[]],[23,0,[\"clickable\"]],[28,\"action\",[[23,0,[]],[23,0,[\"uploadProgress\"]]],null],[28,\"queue\",[[28,\"perform\",[[23,0,[\"success\"]]],null],[23,2,[\"close\"]]],null],[28,\"perform\",[[23,0,[\"preUpload\"]]],null],[28,\"action\",[[23,0,[]],[23,0,[\"error\"]]],null],[28,\"action\",[[23,0,[]],[23,0,[\"buildUrl\"]]],null],[28,\"action\",[[23,0,[]],[23,0,[\"addedFile\"]]],null],[28,\"action\",[[23,0,[]],[28,\"mut\",[[23,0,[\"dropping\"]]],null],true],null],[28,\"action\",[[23,0,[]],[28,\"mut\",[[23,0,[\"dropping\"]]],null],true],null],[28,\"action\",[[23,0,[]],[28,\"mut\",[[23,0,[\"dropping\"]]],null],false],null],[28,\"action\",[[23,0,[]],[28,\"mut\",[[23,0,[\"dropping\"]]],null],false],null]]],{\"statements\":[[0,\"\\n            \"],[14,4,[[28,\"hash\",null,[[\"uploading\"],[[23,0,[\"uploading\"]]]]]]],[0,\"\\n        \"]],\"parameters\":[]}],[0,\"\\n    \"]],\"parameters\":[2]}],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/files/upload-zone/template.hbs"
    }
  });

  _exports.default = _default;
});