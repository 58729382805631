define("osf-components/components/registries/schema-block-renderer/editable/multi-select-input/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "W2DfeFr0",
    "block": "{\"symbols\":[\"form\",\"cb\",\"@changeset\",\"@disabled\",\"@shouldShowMessages\",\"&attrs\",\"@uniqueID\",\"@schemaBlock\",\"@onInput\"],\"statements\":[[5,\"registries/schema-block-renderer/editable/base\",[],[[\"@helpText\"],[[23,0,[\"schemaBlock\",\"helpText\"]]]],{\"statements\":[[0,\"\\n    \"],[5,\"form-controls\",[[13,6]],[[\"@changeset\",\"@disabled\",\"@shouldShowMessages\"],[[23,3,[]],[23,4,[]],[23,5,[]]]],{\"statements\":[[0,\"\\n        \"],[6,[23,1,[\"checkboxes\"]],[[12,\"class\",[29,[[28,\"local-class\",[\"schema-block-multi-select\"],[[\"from\"],[\"osf-components/components/registries/schema-block-renderer/editable/multi-select-input/styles\"]]]]]],[12,\"data-test-multi-select-input\",\"\"]],[[\"@id\",\"@valuePath\",\"@options\",\"@onCheckCallback\"],[[23,7,[]],[23,8,[\"registrationResponseKey\"]],[23,0,[\"optionBlockValues\"]],[23,9,[]]]],{\"statements\":[[0,\"\\n            \"],[7,\"div\",true],[11,\"data-test-multiple-select-option\",[23,2,[\"option\"]]],[8],[0,\"\\n                \"],[1,[23,2,[\"checkbox\"]],false],[0,\"\\n                \"],[7,\"label\",true],[11,\"for\",[23,2,[\"checkboxId\"]]],[8],[0,\"\\n                    \"],[7,\"div\",true],[10,\"data-test-scope-description\",\"\"],[8],[0,\"\\n                        \"],[1,[23,2,[\"option\"]],false],[0,\"\\n                        \"],[5,\"registries/schema-block-renderer/helper-text-icon\",[],[[\"@helpText\"],[[28,\"get\",[[23,0,[\"helpTextMapping\"]],[23,2,[\"option\"]]],null]]]],[0,\"\\n                    \"],[9],[0,\"\\n                \"],[9],[0,\"\\n            \"],[9],[0,\"\\n        \"]],\"parameters\":[2]}],[0,\"\\n    \"]],\"parameters\":[1]}],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/registries/schema-block-renderer/editable/multi-select-input/template.hbs"
    }
  });

  _exports.default = _default;
});