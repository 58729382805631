define("osf-components/components/registries/partial-registration-modal/manager/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mLpq4q2R",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[28,\"hash\",null,[[\"selectedNodes\",\"rootNode\",\"loadAllChildNodes\",\"nodesIncludingRoot\",\"selectAll\",\"clearAll\",\"onChange\",\"isChecked\",\"loadingChildNodes\"],[[23,0,[\"selectedNodes\"]],[23,0,[\"rootNode\"]],[23,0,[\"loadAllChildNodes\"]],[23,0,[\"nodesIncludingRoot\"]],[28,\"action\",[[23,0,[]],[23,0,[\"selectAll\"]]],null],[28,\"action\",[[23,0,[]],[23,0,[\"clearAll\"]]],null],[28,\"action\",[[23,0,[]],[23,0,[\"onChange\"]]],null],[28,\"action\",[[23,0,[]],[23,0,[\"isChecked\"]]],null],[23,0,[\"loadingChildNodes\"]]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/registries/partial-registration-modal/manager/template.hbs"
    }
  });

  _exports.default = _default;
});