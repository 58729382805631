define("osf-components/components/noteworthy-and-popular-project/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "NoteworthyProject__item": "_NoteworthyProject__item_ko80g1",
    "Byline": "_Byline_ko80g1"
  };
  _exports.default = _default;
});