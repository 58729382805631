define("osf-components/components/registries/overview-form-renderer/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "E4YQAsJV",
    "block": "{\"symbols\":[\"group\",\"@registration\"],\"statements\":[[4,\"if\",[[23,0,[\"fetchSchemaBlocks\",\"isRunning\"]]],null,{\"statements\":[[0,\"    \"],[5,\"loading-indicator\",[],[[\"@dark\"],[true]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[5,\"registries/registration-form-navigation-dropdown\",[],[[\"@showMetadata\",\"@schemaBlocks\"],[false,[23,0,[\"schemaBlocks\"]]]]],[0,\"\\n\"],[4,\"each\",[[23,0,[\"schemaBlockGroups\"]]],null,{\"statements\":[[0,\"        \"],[5,\"registries/schema-block-group-renderer\",[],[[\"@schemaBlockGroup\",\"@renderStrategy\"],[[23,1,[]],[28,\"component\",[\"registries/schema-block-renderer/read-only/mapper\"],[[\"registrationResponses\",\"node\"],[[23,2,[\"registrationResponses\"]],[23,2,[]]]]]]]],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/registries/overview-form-renderer/template.hbs"
    }
  });

  _exports.default = _default;
});