define("osf-components/components/validation-errors/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "Errors": "_Errors_10gsqx"
  };
  _exports.default = _default;
});