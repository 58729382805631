define("osf-components/components/osf-layout/registries-side-nav/label/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "d8GY83If",
    "block": "{\"symbols\":[\"@count\",\"&attrs\"],\"statements\":[[7,\"div\",false],[12,\"class\",[29,[[28,\"local-class\",[\"LabelWrapper\"],[[\"from\"],[\"osf-components/components/osf-layout/registries-side-nav/label/styles\"]]]]]],[13,2],[8],[0,\"\\n    \"],[7,\"span\",true],[11,\"class\",[29,[[28,\"local-class\",[\"Label\"],[[\"from\"],[\"osf-components/components/osf-layout/registries-side-nav/label/styles\"]]]]]],[10,\"data-test-label\",\"\"],[8],[0,\"\\n        \"],[1,[23,0,[\"localizedLabel\"]],false],[0,\"\\n    \"],[9],[0,\"\\n\\n\"],[4,\"if\",[[23,0,[\"showCount\"]]],null,{\"statements\":[[0,\"        \"],[7,\"span\",true],[11,\"class\",[29,[[28,\"local-class\",[\"Count\"],[[\"from\"],[\"osf-components/components/osf-layout/registries-side-nav/label/styles\"]]]]]],[10,\"data-test-count\",\"\"],[8],[0,\"\\n\\n            \"],[1,[23,1,[]],false],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/osf-layout/registries-side-nav/label/template.hbs"
    }
  });

  _exports.default = _default;
});