define("osf-components/components/carousel/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "carousel-container": "_carousel-container_1wtw1r",
    "dot-nav": "_dot-nav_1wtw1r",
    "current": "_current_1wtw1r",
    "item-list": "_item-list_1wtw1r",
    "fade": "_fade_1wtw1r",
    "arrow-previous": "_arrow-previous_1wtw1r",
    "btn": "_btn_1wtw1r",
    "btn-next": "_btn-next_1wtw1r"
  };
  _exports.default = _default;
});