define("osf-components/components/noteworthy-and-popular-project/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "j0gbeMa1",
    "block": "{\"symbols\":[],\"statements\":[[5,\"osf-link\",[],[[\"@href\"],[[23,0,[\"project\",\"links\",\"html\"]]]],{\"statements\":[[0,\"\\n    \"],[7,\"div\",true],[11,\"class\",[29,[[28,\"local-class\",[\"NoteworthyProject__item\"],[[\"from\"],[\"osf-components/components/noteworthy-and-popular-project/styles\"]]]]]],[8],[0,\"\\n        \"],[7,\"h5\",true],[10,\"data-test-nnwp-project-title\",\"\"],[8],[1,[23,0,[\"project\",\"title\"]],false],[9],[0,\"\\n        \"],[7,\"span\",true],[11,\"class\",[29,[\"prevent-overflow \",[28,\"local-class\",[\"Byline\"],[[\"from\"],[\"osf-components/components/noteworthy-and-popular-project/styles\"]]]]]],[8],[0,\"\\n            \"],[7,\"i\",true],[8],[0,\"\\n                \"],[1,[28,\"t\",[\"dashboard.noteworthy.by\"],null],false],[0,\"\\n                \"],[5,\"contributor-list\",[[12,\"data-test-nnwp-project-contributors\",\"\"]],[[\"@node\"],[[23,0,[\"project\"]]]]],[0,\"\\n            \"],[9],[0,\"\\n        \"],[9],[0,\"\\n        \"],[7,\"p\",true],[10,\"data-test-nnwp-project-description\",\"\"],[10,\"class\",\"prevent-overflow\"],[8],[0,\"\\n            \"],[5,\"bs-tooltip\",[],[[\"@title\"],[[23,0,[\"compactDescription\"]]]]],[0,\"\\n            \"],[1,[23,0,[\"project\",\"description\"]],false],[0,\"\\n        \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/noteworthy-and-popular-project/template.hbs"
    }
  });

  _exports.default = _default;
});