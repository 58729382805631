define("osf-components/components/subjects/browse/browse-manager/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xvUBobda",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[28,\"hash\",null,[[\"rootSubjects\",\"isLoading\"],[[23,0,[\"rootSubjects\"]],[23,0,[\"loadRootSubjects\",\"isRunning\"]]]]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/subjects/browse/browse-manager/template.hbs"
    }
  });

  _exports.default = _default;
});