define("osf-components/components/get-started-button/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "startButton": "_startButton_1hkaua"
  };
  _exports.default = _default;
});