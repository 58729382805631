define("osf-components/components/subjects/search/search-result/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "j6j411ew",
    "block": "{\"symbols\":[\"inputId\",\"ancestor\",\"@singleSubjectManager\",\"&attrs\"],\"statements\":[[7,\"li\",false],[12,\"class\",[29,[[28,\"local-class\",[\"SearchResult\"],[[\"from\"],[\"osf-components/components/subjects/search/search-result/styles\"]]]]]],[13,4],[8],[0,\"\\n\"],[4,\"let\",[[28,\"unique-id\",[\"search-result\"],null]],null,{\"statements\":[[0,\"        \"],[5,\"input\",[],[[\"@id\",\"@type\",\"@change\",\"@checked\"],[[23,1,[]],\"checkbox\",[23,3,[\"toggleSelected\"]],[23,3,[\"isSelected\"]]]]],[0,\"\\n\\n        \"],[7,\"span\",true],[11,\"class\",[29,[[28,\"local-class\",[\"Ancestry\"],[[\"from\"],[\"osf-components/components/subjects/search/search-result/styles\"]]]]]],[8],[0,\"\\n\"],[4,\"each\",[[23,0,[\"subjectAncestry\"]]],null,{\"statements\":[[0,\"                \"],[7,\"span\",true],[11,\"class\",[29,[[28,\"local-class\",[\"AncestorTitle\"],[[\"from\"],[\"osf-components/components/subjects/search/search-result/styles\"]]]]]],[8],[0,\"\\n                    \"],[1,[23,2,[\"text\"]],false],[0,\"\\n                \"],[9],[0,\"\\n                \"],[7,\"span\",true],[11,\"class\",[29,[[28,\"local-class\",[\"Delimiter\"],[[\"from\"],[\"osf-components/components/subjects/search/search-result/styles\"]]]]]],[8],[0,\"/\"],[9],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"        \"],[9],[0,\"\\n\\n        \"],[7,\"label\",true],[11,\"class\",[29,[[28,\"local-class\",[\"Subject\"],[[\"from\"],[\"osf-components/components/subjects/search/search-result/styles\"]]]]]],[11,\"for\",[23,1,[]]],[8],[0,\"\\n\"],[4,\"if\",[[23,0,[\"subject\"]]],null,{\"statements\":[[0,\"                \"],[1,[23,0,[\"subject\",\"text\"]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"                \"],[1,[28,\"random-text\",null,[[\"wordsPerSentence\"],[7]]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"        \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/subjects/search/search-result/template.hbs"
    }
  });

  _exports.default = _default;
});