define("osf-components/components/registries/page-renderer/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wA6bO9mu",
    "block": "{\"symbols\":[\"group\",\"@onMetadataInput\",\"@onInput\",\"@node\",\"@pageManager\"],\"statements\":[[4,\"each\",[[23,5,[\"schemaBlockGroups\"]]],null,{\"statements\":[[0,\"    \"],[5,\"registries/schema-block-group-renderer\",[],[[\"@schemaBlockGroup\",\"@renderStrategy\"],[[23,1,[]],[28,\"component\",[\"registries/schema-block-renderer/editable/mapper\"],[[\"changeset\",\"node\",\"onInput\",\"metadataChangeset\",\"metadataNodeErad\",\"metadataNodeProject\",\"onMetadataInput\",\"draftManager\",\"schemaBlockGroup\"],[[23,0,[\"pageManager\",\"changeset\"]],[23,4,[]],[23,3,[]],[23,0,[\"draftManager\",\"metadataChangeset\"]],[23,0,[\"draftManager\",\"metadataNodeErad\"]],[23,0,[\"draftManager\",\"metadataNodeProject\"]],[23,2,[]],[23,0,[\"draftManager\"]],[23,1,[]]]]]]]],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/registries/page-renderer/template.hbs"
    }
  });

  _exports.default = _default;
});