define("osf-components/components/registries/finalize-registration-modal/manager/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ce7HPh3A",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[28,\"hash\",null,[[\"setEmbargoEndDate\",\"setCreateDoi\",\"submitRegistration\",\"registration\",\"hasEmbargoEndDate\",\"submittingRegistration\"],[[28,\"action\",[[23,0,[]],[23,0,[\"setEmbargoEndDate\"]]],null],[28,\"action\",[[23,0,[]],[23,0,[\"setCreateDoi\"]]],null],[28,\"perform\",[[23,0,[\"submitRegistration\"]]],null],[23,0,[\"registration\"]],[23,0,[\"hasEmbargoEndDate\"]],[23,0,[\"submittingRegistration\"]]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/registries/finalize-registration-modal/manager/template.hbs"
    }
  });

  _exports.default = _default;
});