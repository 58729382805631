define("osf-components/components/validated-input/power-select/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cMQRDIRn",
    "block": "{\"symbols\":[\"option\",\"@searchField\",\"&default\"],\"statements\":[[4,\"validated-input/x-input-wrapper\",null,[[\"model\",\"changeset\",\"errors\",\"valuePath\",\"validationStatus\"],[[23,0,[\"model\"]],[23,0,[\"changeset\"]],[23,0,[\"errors\"]],[23,0,[\"valuePath\"]],[23,0,[\"validationStatus\"]]]],{\"statements\":[[0,\"    \"],[5,\"power-select\",[],[[\"@id\",\"@name\",\"@options\",\"@selected\",\"@onchange\",\"@search\",\"@noMatchesMessage\",\"@disabled\",\"@searchEnabled\",\"@placeholder\",\"@renderInPlace\",\"@searchField\"],[[23,0,[\"valuePath\"]],[23,0,[\"valuePath\"]],[23,0,[\"options\"]],[28,\"or\",[[28,\"get\",[[23,0,[\"modelOrChangeset\"]],[23,0,[\"valuePath\"]]],null],null],null],[28,\"action\",[[23,0,[]],[23,0,[\"powerSelectChanged\"]]],null],[23,0,[\"search\"]],[23,0,[\"noMatchesMessage\"]],[23,0,[\"disabled\"]],[23,0,[\"searchEnabled\"]],[23,0,[\"placeholder\"]],[23,0,[\"renderInPlace\"]],[23,2,[]]]],{\"statements\":[[0,\"\\n        \"],[14,3,[[23,1,[]]]],[0,\"\\n    \"]],\"parameters\":[1]}],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/validated-input/power-select/template.hbs"
    }
  });

  _exports.default = _default;
});