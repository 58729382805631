define("osf-components/components/subjects/browse/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Azzzck1l",
    "block": "{\"symbols\":[\"browseManager\",\"@subjectsManager\"],\"statements\":[[5,\"subjects/browse/browse-manager\",[],[[\"@subjectsManager\"],[[23,2,[]]]],{\"statements\":[[0,\"\\n    \"],[5,\"subjects/browse/item-list\",[],[[\"@items\",\"@expectedNumItems\",\"@subjectsManager\",\"@ItemManager\"],[[23,1,[\"rootSubjects\"]],10,[23,2,[]],[28,\"component\",[\"subjects/manager/single\"],[[\"subjectsManager\"],[[23,2,[]]]]]]]],[0,\"\\n\"]],\"parameters\":[1]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/subjects/browse/template.hbs"
    }
  });

  _exports.default = _default;
});