define("osf-components/components/subjects/widget/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "X21t0vVo",
    "block": "{\"symbols\":[\"tab\",\"@subjectsManager\"],\"statements\":[[5,\"subjects/display\",[],[[\"@subjects\",\"@removeSubject\"],[[23,2,[\"selectedSubjects\"]],[23,2,[\"unselectSubject\"]]]]],[0,\"\\n\\n\"],[5,\"bs-tab\",[[12,\"class\",[29,[[28,\"local-class\",[\"Tabs\"],[[\"from\"],[\"osf-components/components/subjects/widget/styles\"]]]]]]],[[],[]],{\"statements\":[[0,\"\\n    \"],[6,[23,1,[\"pane\"]],[],[[\"@title\"],[[28,\"t\",[\"osf-components.subjects.browse.browse_all\"],null]]],{\"statements\":[[0,\"\\n        \"],[5,\"subjects/browse\",[[12,\"data-analytics-scope\",\"Browse\"]],[[\"@subjectsManager\"],[[23,2,[]]]]],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n    \"],[6,[23,1,[\"pane\"]],[],[[\"@title\"],[[28,\"t\",[\"osf-components.subjects.search.search_all\"],null]]],{\"statements\":[[0,\"\\n        \"],[5,\"subjects/search\",[[12,\"data-analytics-scope\",\"Search\"]],[[\"@subjectsManager\"],[[23,2,[]]]]],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/subjects/widget/template.hbs"
    }
  });

  _exports.default = _default;
});