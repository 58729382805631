define("osf-components/components/validation-errors/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Oa959zEG",
    "block": "{\"symbols\":[\"error\",\"@key\",\"&attrs\",\"@isValidating\"],\"statements\":[[4,\"if\",[[23,4,[]]],null,{\"statements\":[[0,\"    \"],[5,\"loading-indicator\",[],[[\"@inline\",\"@dark\"],[true,true]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,0,[\"validatorErrors\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\",false],[12,\"class\",[29,[[28,\"local-class\",[\"Errors\"],[[\"from\"],[\"osf-components/components/validation-errors/styles\"]]]]]],[12,\"data-test-validation-errors\",[23,2,[]]],[13,3],[8],[0,\"\\n\"],[4,\"each\",[[23,0,[\"validatorErrors\"]]],null,{\"statements\":[[0,\"                \"],[7,\"p\",true],[8],[1,[23,1,[]],false],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"        \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/validation-errors/template.hbs"
    }
  });

  _exports.default = _default;
});