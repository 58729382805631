define("osf-components/components/delete-button/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "SmallDelete": "_SmallDelete_11iucx",
    "Modal__confirmBlock": "_Modal__confirmBlock_11iucx",
    "Modal__confirmPrompt": "_Modal__confirmPrompt_11iucx",
    "Modal__title": "_Modal__title_11iucx"
  };
  _exports.default = _default;
});