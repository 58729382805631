define("osf-components/components/osf-layout/right-column/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WqaAJ6W9",
    "block": "{\"symbols\":[\"rightGutter\",\"@gutters\",\"&attrs\",\"@toggleMetadata\",\"&default\"],\"statements\":[[6,[23,2,[\"right-gutter\"]],[[12,\"class\",[29,[[28,\"local-class\",[\"RightColumn\"],[[\"from\"],[\"osf-components/components/osf-layout/right-column/styles\"]]]]]],[13,3]],[[],[]],{\"statements\":[[0,\"\\n    \"],[14,5,[[28,\"hash\",null,[[\"right\",\"rightGutterMode\",\"toggleMetadata\"],[[28,\"component\",[[28,\"-element\",[\"div\"],null]],[[\"tagName\"],[\"div\"]]],[23,1,[\"mode\"]],[23,4,[]]]]]]],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/osf-layout/right-column/template.hbs"
    }
  });

  _exports.default = _default;
});