define("osf-components/components/sort-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VA/z2KY+",
    "block": "{\"symbols\":[\"@sort\",\"@sortAction\"],\"statements\":[[5,\"osf-button\",[[12,\"data-test-ascending-sort\",[23,0,[\"sortBy\"]]],[12,\"data-analytics-name\",[28,\"concat\",[\"Ascending sort \",[23,0,[\"sortBy\"]]],null]],[12,\"class\",[28,\"if\",[[28,\"eq\",[[23,1,[]],[23,0,[\"sortBy\"]]],null],\"selected\",\"not-selected\"],null]]],[[\"@onClick\",\"@title\"],[[28,\"action\",[[23,0,[]],[23,2,[]],[23,0,[\"sortBy\"]]],null],[28,\"t\",[\"general.sort_asc\"],null]]],{\"statements\":[[0,\"\\n    \"],[1,[28,\"fa-icon\",[\"chevron-up\"],null],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[5,\"osf-button\",[[12,\"data-test-descending-sort\",[23,0,[\"sortBy\"]]],[12,\"data-analytics-name\",[28,\"concat\",[\"Descending sort \",[23,0,[\"sortBy\"]]],null]],[12,\"class\",[28,\"if\",[[28,\"eq\",[[23,1,[]],[23,0,[\"sortByDesc\"]]],null],\"selected\",\"not-selected\"],null]]],[[\"@onClick\",\"@title\"],[[28,\"action\",[[23,0,[]],[23,2,[]],[23,0,[\"sortByDesc\"]]],null],[28,\"t\",[\"general.sort_desc\"],null]]],{\"statements\":[[0,\"\\n    \"],[1,[28,\"fa-icon\",[\"chevron-down\"],null],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/sort-button/template.hbs"
    }
  });

  _exports.default = _default;
});