define("osf-components/components/editable-field/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "Editable__header": "_Editable__header_svy88h",
    "EditButton": "_EditButton_svy88h",
    "DialogMain": "_DialogMain_svy88h"
  };
  _exports.default = _default;
});