define("osf-components/components/editable-field/institutions-manager/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yZEZW4KR",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[28,\"hash\",null,[[\"save\",\"cancel\",\"toggleInstitution\",\"fieldIsEmpty\",\"emptyFieldText\",\"affiliatedList\",\"startEditing\",\"inEditMode\",\"userCanEdit\",\"shouldShowField\",\"bindReload\",\"node\",\"user\",\"isSaving\"],[[28,\"perform\",[[23,0,[\"save\"]]],null],[28,\"action\",[[23,0,[]],[23,0,[\"cancel\"]]],null],[28,\"action\",[[23,0,[]],[23,0,[\"toggleInstitution\"]]],null],[23,0,[\"fieldIsEmpty\"]],[23,0,[\"emptyFieldText\"]],[23,0,[\"currentAffiliatedList\"]],[28,\"action\",[[23,0,[]],[23,0,[\"startEditing\"]]],null],[23,0,[\"inEditMode\"]],[23,0,[\"userCanEdit\"]],[23,0,[\"shouldShowField\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[23,0,[\"reloadList\"]]],null]],null],[23,0,[\"node\"]],[23,0,[\"currentUser\",\"user\"]],[23,0,[\"save\",\"isRunning\"]]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/editable-field/institutions-manager/template.hbs"
    }
  });

  _exports.default = _default;
});