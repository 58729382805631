define("osf-components/components/panel/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "s6BJCj23",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"panel panel-default \",[28,\"local-class\",[\"Panel\"],[[\"from\"],[\"osf-components/components/panel/styles\"]]]]]],[10,\"data-test-panel\",\"\"],[8],[0,\"\\n    \"],[14,1,[[28,\"hash\",null,[[\"heading\"],[[28,\"component\",[\"panel/x-heading\"],null]]]]]],[0,\"\\n    \"],[14,1,[[28,\"hash\",null,[[\"body\"],[[28,\"component\",[\"panel/x-body\"],null]]]]]],[0,\"\\n    \"],[14,1,[[28,\"hash\",null,[[\"footer\"],[[28,\"component\",[\"panel/x-footer\"],null]]]]]],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/panel/template.hbs"
    }
  });

  _exports.default = _default;
});