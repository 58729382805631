define("osf-components/components/registries/schema-block-renderer/editable/rdm/e-rad-award-title-ja-input/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "schema-block-input": "_schema-block-input_16tzym"
  };
  _exports.default = _default;
});