define("osf-components/components/subjects/browse/item/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "Item": "_Item_12j7gr",
    "ItemLabel": "_ItemLabel_12j7gr",
    "with-selected-child": "_with-selected-child_12j7gr",
    "ExpandButton": "_ExpandButton_12j7gr",
    "Children": "_Children_12j7gr"
  };
  _exports.default = _default;
});