define("osf-components/components/sort-button/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "SortButton": "_SortButton_1ifm79 sort-group"
  };
  _exports.default = _default;
});