define("osf-components/components/osf-layout/registries-side-nav/icon/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Kz+5kYaI",
    "block": "{\"symbols\":[\"@icon\"],\"statements\":[[5,\"fa-icon\",[[12,\"class\",[29,[[28,\"local-class\",[\"Icon\"],[[\"from\"],[\"osf-components/components/osf-layout/registries-side-nav/icon/styles\"]]]]]],[12,\"data-test-icon\",\"\"]],[[\"@fixedWidth\",\"@icon\"],[true,[23,1,[]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/osf-layout/registries-side-nav/icon/template.hbs"
    }
  });

  _exports.default = _default;
});