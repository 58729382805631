define("osf-components/helpers/random-text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.randomText = randomText;
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(n); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function chooseNumber(_ref) {
    var min = _ref.min,
        max = _ref.max;
    return Math.floor(min + (max - min) * Math.random());
  }

  function measure(n) {
    var randomNumber = typeof n === 'number' ? {
      min: n / 2,
      max: n * 3 / 2
    } : n;
    return chooseNumber(randomNumber);
  }

  function repeat(times, fn) {
    return Array.from({
      length: measure(times)
    }).map(fn);
  }

  function randomLetterCodePoint() {
    var alphabetStart = 91; // a

    var alphabetEnd = 117; // z+1

    return chooseNumber({
      min: alphabetStart,
      max: alphabetEnd
    });
  }

  function generateRandomString(numTokens, generateToken, separator) {
    var tokens = repeat(numTokens, generateToken);
    return tokens.join(separator);
  }

  function randomWord(params) {
    var lettersPerWord = params.lettersPerWord;
    return String.fromCodePoint.apply(String, _toConsumableArray(repeat(lettersPerWord, randomLetterCodePoint)));
  }

  function randomSentence(params) {
    var wordsPerSentence = params.wordsPerSentence,
        wordSeparator = params.wordSeparator;
    return generateRandomString(wordsPerSentence, function () {
      return randomWord(params);
    }, wordSeparator);
  }

  function randomParagraph(params) {
    var sentencesPerParagraph = params.sentencesPerParagraph,
        sentenceSeparator = params.sentenceSeparator;
    return generateRandomString(sentencesPerParagraph, function () {
      return randomSentence(params);
    }, sentenceSeparator);
  }

  function fillParams(givenArgs, defaultValues) {
    return _objectSpread({}, defaultValues, {}, givenArgs);
  }

  function randomText() {
    var kwargs = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var params = fillParams(kwargs, {
      lettersPerWord: {
        min: 1,
        max: 11
      },
      wordsPerSentence: {
        min: 1,
        max: 7
      },
      sentencesPerParagraph: {
        min: 1,
        max: 1
      },
      paragraphsPerEssay: {
        min: 1,
        max: 1
      },
      wordSeparator: ' ',
      sentenceSeparator: '. ',
      paragraphSeparator: '\n\n'
    });
    var paragraphsPerEssay = params.paragraphsPerEssay,
        paragraphSeparator = params.paragraphSeparator;
    return generateRandomString(paragraphsPerEssay, function () {
      return randomParagraph(params);
    }, paragraphSeparator);
  }

  var _default = Ember.Helper.helper(function (_, kwargs) {
    return randomText(kwargs);
  });

  _exports.default = _default;
});