define("ember-oo-modifiers/-private/modifier-classic", ["exports", "ember-oo-modifiers/-private/create-manager"], function (_exports, _createManager) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Modifier = Ember.Object.extend({
    element: null,
    didInsertElement: function didInsertElement() {},
    didRecieveArguments: function didRecieveArguments() {},
    didUpdateArguments: function didUpdateArguments() {},
    willDestroyElement: function willDestroyElement() {}
  });
  Modifier.reopenClass({
    modifier: function modifier(Klass) {
      return Ember._setModifierManager(_createManager.default, Klass);
    }
  });
  var _default = Modifier;
  _exports.default = _default;
});