define("osf-components/components/osf-navbar/auth-dropdown/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "Headline": "_Headline_15bw95",
    "AuthDropdown": "_AuthDropdown_15bw95",
    "LogOutButton": "_LogOutButton_15bw95"
  };
  _exports.default = _default;
});