define("osf-components/components/maintenance-banner/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vlhNiTml",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,0,[\"maintenance\"]]],null,{\"statements\":[[0,\"    \"],[5,\"bs-alert\",[[12,\"data-analytics-scope\",\"Maintenance banner\"]],[[\"@type\",\"@onDismiss\"],[[23,0,[\"alertType\"]],[28,\"action\",[[23,0,[]],[23,0,[\"dismiss\"]]],null]]],{\"statements\":[[0,\"\\n        \"],[7,\"strong\",true],[8],[1,[28,\"t\",[\"maintenance.title\"],null],false],[9],[0,\"\\n\"],[4,\"if\",[[23,0,[\"maintenance\",\"message\",\"length\"]]],null,{\"statements\":[[0,\"            \"],[1,[23,0,[\"maintenance\",\"message\"]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"            \"],[1,[28,\"t\",[\"maintenance.line1\"],[[\"start\",\"end\",\"utc\",\"htmlSafe\"],[[23,0,[\"start\"]],[23,0,[\"end\"]],[23,0,[\"utc\"]],true]]],false],[0,\"\\n            \"],[1,[28,\"t\",[\"maintenance.line2\"],null],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/maintenance-banner/template.hbs"
    }
  });

  _exports.default = _default;
});