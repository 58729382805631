define("osf-components/components/osf-layout/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bP1zQKp4",
    "block": "{\"symbols\":[\"gutters\",\"&default\"],\"statements\":[[14,2,[[28,\"hash\",null,[[\"heading\",\"sidenavGutterClosed\",\"toggleSidenav\",\"metadataGutterClosed\",\"toggleMetadata\"],[[28,\"component\",[[28,\"-element\",[\"div\"],null]],[[\"tagName\"],[\"div\"]]],[23,0,[\"sidenavGutterClosed\"]],[28,\"action\",[[23,0,[]],[23,0,[\"toggleSidenav\"]]],null],[23,0,[\"metadataGutterClosed\"]],[28,\"action\",[[23,0,[]],[23,0,[\"toggleMetadata\"]]],null]]]]]],[0,\"\\n\\n\"],[7,\"div\",true],[11,\"class\",[23,0,[\"backgroundClass\"]]],[8],[0,\"\\n    \"],[14,2,[[28,\"hash\",null,[[\"top\"],[[28,\"component\",[\"osf-layout/top\"],[[\"toggleSidenav\",\"sidenavGutterClosed\"],[[28,\"action\",[[23,0,[]],[23,0,[\"toggleSidenav\"]]],null],[23,0,[\"sidenavGutterClosed\"]]]]]]]]]],[0,\"\\n    \"],[5,\"gutters\",[],[[\"@leftMode\",\"@leftClosed\",\"@rightMode\",\"@rightClosed\"],[[23,0,[\"sidenavGutterMode\"]],[23,0,[\"sidenavGutterClosed\"]],[23,0,[\"metadataGutterMode\"]],[23,0,[\"metadataGutterClosed\"]]]],{\"statements\":[[0,\"\\n        \"],[14,2,[[28,\"hash\",null,[[\"leftNavOld\",\"leftNav\",\"left\",\"main\",\"right\"],[[28,\"component\",[\"osf-layout/left-nav-old\"],[[\"gutters\",\"toggleSidenav\"],[[23,1,[]],[28,\"action\",[[23,0,[]],[23,0,[\"toggleSidenav\"]]],null]]]],[28,\"component\",[\"osf-layout/left-nav\"],[[\"gutters\",\"toggleSidenav\"],[[23,1,[]],[28,\"action\",[[23,0,[]],[23,0,[\"toggleSidenav\"]]],null]]]],[28,\"component\",[\"osf-layout/left-column\"],[[\"gutters\",\"toggleSidenav\"],[[23,1,[]],[28,\"action\",[[23,0,[]],[23,0,[\"toggleSidenav\"]]],null]]]],[28,\"component\",[\"osf-layout/main-column\"],[[\"gutters\"],[[23,1,[]]]]],[28,\"component\",[\"osf-layout/right-column\"],[[\"gutters\",\"toggleMetadata\"],[[23,1,[]],[28,\"action\",[[23,0,[]],[23,0,[\"toggleMetadata\"]]],null]]]]]]]]],[0,\"\\n    \"]],\"parameters\":[1]}],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/osf-layout/template.hbs"
    }
  });

  _exports.default = _default;
});