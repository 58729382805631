define("osf-components/components/osf-logo/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uZMkPFjm",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[[28,\"local-class\",[[28,\"concat\",[\"OsfLogo \",[28,\"if\",[[23,0,[\"double\"]],\"Double\"],null],\" \",[28,\"if\",[[23,0,[\"animate\"]],\"Animate\"],null]],null]],[[\"from\"],[\"osf-components/components/osf-logo/styles\"]]]]]],[8],[0,\"\\n\"],[4,\"each\",[[28,\"range\",[0,8],null]],null,{\"statements\":[[0,\"        \"],[7,\"div\",true],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/osf-logo/template.hbs"
    }
  });

  _exports.default = _default;
});