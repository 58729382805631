define("osf-components/components/osf-layout/registries-side-nav/x-link/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "Link": "_Link_1bn4pf",
    "CurrentPage": "_CurrentPage_1bn4pf",
    "Count": "_Count_1bn4pf",
    "Collapsed": "_Collapsed_1bn4pf",
    "Drawer": "_Drawer_1bn4pf"
  };
  _exports.default = _default;
});