define("osf-components/components/osf-layout/registries-side-nav/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "Links": "_Links_1gg4gs",
    "Collapsed": "_Collapsed_1gg4gs",
    "SideNav": "_SideNav_1gg4gs",
    "Toggle": "_Toggle_1gg4gs",
    "Line": "_Line_1gg4gs"
  };
  _exports.default = _default;
});