define("osf-components/components/draft-registration-card/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "DraftRegistrationCard": "_DraftRegistrationCard_169oe3",
    "DraftRegistratrionCard__title": "_DraftRegistratrionCard__title_169oe3",
    "DraftRegistrationCard__body": "_DraftRegistrationCard__body_169oe3"
  };
  _exports.default = _default;
});