define("osf-components/components/registries/hierarchical-list/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VLPzPC0p",
    "block": "{\"symbols\":[\"child\",\"childManager\",\"@manager\",\"&attrs\"],\"statements\":[[7,\"li\",true],[11,\"data-test-item\",[23,3,[\"item\",\"id\"]]],[8],[0,\"\\n    \"],[5,\"input\",[],[[\"@type\",\"@change\",\"@checked\",\"@disabled\"],[\"checkbox\",[28,\"action\",[[23,0,[]],[23,3,[\"onChange\"]]],null],[23,3,[\"itemChecked\"]],[23,3,[\"isRoot\"]]]]],[0,\"\\n    \"],[5,\"osf-button\",[[12,\"class\",[29,[[28,\"local-class\",[\"expand-button\"],[[\"from\"],[\"osf-components/components/registries/hierarchical-list/item/styles\"]]]]]],[12,\"data-test-expand-child\",[23,3,[\"item\",\"id\"]]]],[[\"@type\",\"@onClick\"],[\"link\",[28,\"action\",[[23,0,[]],[23,3,[\"toggleShowChildren\"]]],null]]],{\"statements\":[[0,\"\\n        \"],[1,[23,3,[\"item\",\"title\"]],false],[0,\"\\n\"],[4,\"if\",[[23,3,[\"children\"]]],null,{\"statements\":[[0,\"            \"],[5,\"fa-icon\",[],[[\"@icon\",\"@fixedWidth\"],[[29,[\"caret-\",[28,\"if\",[[23,3,[\"shouldShowChildren\"]],\"up\",\"down\"],null]]],true]]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"]],\"parameters\":[]}],[0,\"\\n\"],[9],[0,\"\\n\"],[4,\"if\",[[28,\"and\",[[23,3,[\"children\"]],[23,3,[\"shouldShowChildren\"]]],null]],null,{\"statements\":[[0,\"    \"],[7,\"ul\",false],[13,4],[8],[0,\"\\n\"],[4,\"each\",[[23,3,[\"children\"]]],null,{\"statements\":[[0,\"            \"],[5,\"registries/hierarchical-list/item-manager\",[],[[\"@item\",\"@listManager\"],[[23,1,[]],[23,3,[\"listManager\"]]]],{\"statements\":[[0,\"\\n                \"],[5,\"registries/hierarchical-list/item\",[[13,4]],[[\"@manager\"],[[23,2,[]]]]],[0,\"\\n            \"]],\"parameters\":[2]}],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/registries/hierarchical-list/item/template.hbs"
    }
  });

  _exports.default = _default;
});