define("ember-oo-modifiers/index", ["exports", "ember-oo-modifiers/-private/modifier-native", "ember-oo-modifiers/-private/modifier-classic"], function (_exports, _modifierNative, _modifierClassic) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "Modifier", {
    enumerable: true,
    get: function get() {
      return _modifierClassic.default;
    }
  });
  _exports.default = void 0;
  var _default = _modifierNative.default;
  _exports.default = _default;
});