define("osf-components/components/osf-placeholder/circle/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "placeholder_item": "_placeholder_item_8sh541",
    "gradient": "_gradient_8sh541"
  };
  _exports.default = _default;
});