define("osf-components/components/responsive-dropdown/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "reg-modal-dialog": "_reg-modal-dialog_my0f9e",
    "Trigger": "_Trigger_my0f9e"
  };
  _exports.default = _default;
});