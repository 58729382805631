define("osf-components/components/osf-dialog/heading/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "HeadingWrapper": "_HeadingWrapper_11qply",
    "Heading": "_Heading_11qply",
    "CloseButton": "_CloseButton_11qply"
  };
  _exports.default = _default;
});