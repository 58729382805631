define("osf-components/components/osf-placeholder/circle/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gMpfcMc6",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[[28,\"local-class\",[\"placeholder_item\"],[[\"from\"],[\"osf-components/components/osf-placeholder/circle/styles\"]]]]]],[8],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/osf-placeholder/circle/template.hbs"
    }
  });

  _exports.default = _default;
});