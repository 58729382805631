define("osf-components/components/quickfile-nav/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "Navbar": "_Navbar_6h1gyn",
    "NavbarList": "_NavbarList_6h1gyn",
    "NavbarList__item": "_NavbarList__item_6h1gyn",
    "NavbarList__title": "_NavbarList__title_6h1gyn",
    "NavbarList__title--active": "_NavbarList__title--active_6h1gyn",
    "NavBarBuffer": "_NavBarBuffer_6h1gyn"
  };
  _exports.default = _default;
});