define("osf-components/components/registries/schema-block-renderer/read-only/response/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "68lmVf0Z",
    "block": "{\"symbols\":[\"@changeset\",\"@schemaBlock\"],\"statements\":[[7,\"p\",true],[11,\"class\",[29,[[28,\"local-class\",[[28,\"concat\",[\"ResponseValue \",[28,\"unless\",[[23,0,[\"readOnlyValue\"]],\"NoResponse\"],null]],null]],[[\"from\"],[\"osf-components/components/registries/schema-block-renderer/read-only/response/styles\"]]]]]],[11,\"data-test-read-only-response\",[23,0,[\"schemaBlock\",\"registrationResponseKey\"]]],[8],[1,[28,\"if\",[[23,0,[\"readOnlyValue\"]],[23,0,[\"readOnlyValue\"]],[28,\"t\",[\"osf-components.registries.schema-block-renderer/read-only/response.noResponse\"],null]],null],false],[9],[0,\"\\n\"],[4,\"if\",[[23,1,[]]],null,{\"statements\":[[0,\"    \"],[5,\"validation-errors\",[[12,\"class\",[29,[[28,\"local-class\",[\"ValidationErrors\"],[[\"from\"],[\"osf-components/components/registries/schema-block-renderer/read-only/response/styles\"]]]]]]],[[\"@changeset\",\"@key\"],[[23,1,[]],[23,2,[\"registrationResponseKey\"]]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/registries/schema-block-renderer/read-only/response/template.hbs"
    }
  });

  _exports.default = _default;
});