define("collections/routes", ["exports", "ember-engines/routes"], function (_exports, _routes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _routes.default)(function () {
    this.route('page-not-found');
    this.route('forbidden');
    this.route('discover');
    this.route('submit');
    this.route('guid', {
      path: '/:guid'
    }, function () {
      this.route('edit');
    });
    this.route('provider', {
      path: '/:slug'
    }, function () {
      this.route('page-not-found');
      this.route('forbidden');
      this.route('discover');
      this.route('submit');
      this.route('guid', {
        path: '/:guid'
      }, function () {
        this.route('edit');
      });
    });
  });

  _exports.default = _default;
});