define("osf-components/components/tags-widget/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Pb7idzoS",
    "block": "{\"symbols\":[\"tag\",\"@taggable\"],\"statements\":[[4,\"unless\",[[23,2,[\"tags\",\"length\"]]],null,{\"statements\":[[0,\"    \"],[7,\"span\",true],[10,\"data-test-no-tags\",\"\"],[8],[0,\"\\n        \"],[1,[28,\"t\",[\"osf-components.tags-widget.no_tags\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[5,\"tag-input\",[[12,\"class\",[29,[[28,\"local-class\",[\"TagsWidget\"],[[\"from\"],[\"osf-components/components/tags-widget/styles\"]]]]]],[12,\"data-test-tags-widget-tag-input\",\"\"],[12,\"aria-label\",[28,\"t\",[\"osf-components.tags-widget.tags\"],null]]],[[\"@tags\",\"@addTag\",\"@removeTagAtIndex\",\"@allowSpacesInTags\",\"@placeholder\",\"@readOnly\",\"@maxlength\"],[[23,2,[\"tags\"]],[28,\"action\",[[23,0,[]],[23,0,[\"_addTag\"]]],null],[28,\"action\",[[23,0,[]],[23,0,[\"_removeTag\"]]],null],true,[28,\"t\",[\"osf-components.tags-widget.add_tag\"],null],[23,0,[\"readOnly\"]],1024]],{\"statements\":[[0,\"\\n    \"],[7,\"span\",false],[12,\"data-test-tags-widget-tag\",[29,[[23,1,[]]]]],[12,\"data-analytics-name\",\"Click tag\"],[12,\"role\",\"link\"],[3,\"action\",[[23,0,[]],[23,0,[\"_clickTag\"]],[23,1,[]]]],[8],[0,\"\\n        \"],[1,[23,1,[]],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/tags-widget/template.hbs"
    }
  });

  _exports.default = _default;
});