define("osf-components/components/registries/schema-block-renderer/helper-text-icon/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "helper-text-icon": "_helper-text-icon_1qczwe"
  };
  _exports.default = _default;
});