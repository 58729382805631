define("osf-components/components/node-publication-doi-editable/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "B73gtloN",
    "block": "{\"symbols\":[\"@manager\"],\"statements\":[[7,\"form\",true],[8],[0,\"\\n    \"],[5,\"validated-input/custom\",[[12,\"data-test-publication-doi-input\",\"\"]],[[\"@changeset\",\"@valuePath\",\"@shouldShowMessages\",\"@placeholder\"],[[23,1,[\"changeset\"]],\"articleDoi\",[23,1,[\"didValidate\"]],[23,0,[\"doiPlaceholder\"]]]],{\"statements\":[[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"input-group\"],[8],[0,\"\\n            \"],[7,\"span\",true],[10,\"class\",\"input-group-addon\"],[8],[1,[23,0,[\"doiUrlPrefix\"]],false],[9],[0,\"\\n            \"],[5,\"input\",[],[[\"@value\",\"@type\",\"@class\",\"@placeholder\"],[[28,\"get\",[[23,1,[\"changeset\"]],\"articleDoi\"],null],\"text\",\"form-control form-group\",[23,0,[\"doiPlaceholder\"]]]]],[0,\"\\n        \"],[9],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\\n    \"],[7,\"div\",true],[11,\"class\",[29,[[28,\"local-class\",[\"Controls\"],[[\"from\"],[\"osf-components/components/node-publication-doi-editable/styles\"]]]]]],[8],[0,\"\\n        \"],[5,\"osf-button\",[[12,\"data-analytics-name\",\"Cancel\"],[12,\"data-test-cancel-publication-doi\",\"\"]],[[\"@onClick\",\"@disabled\"],[[28,\"action\",[[23,0,[]],[23,1,[\"cancel\"]]],null],[23,1,[\"save\",\"isRunning\"]]]],{\"statements\":[[0,\"\\n            \"],[1,[28,\"t\",[\"general.cancel\"],null],false],[0,\"\\n        \"]],\"parameters\":[]}],[0,\"\\n        \"],[5,\"osf-button\",[[12,\"data-analytics-name\",\"Save\"],[12,\"data-test-save-publication-doi\",\"\"]],[[\"@type\",\"@buttonType\",\"@onClick\",\"@disabled\"],[\"primary\",\"submit\",[28,\"action\",[[23,0,[]],[23,1,[\"save\"]]],null],[23,1,[\"save\",\"isRunning\"]]]],{\"statements\":[[0,\"\\n            \"],[1,[28,\"t\",[\"general.save\"],null],false],[0,\"\\n        \"]],\"parameters\":[]}],[0,\"\\n    \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/node-publication-doi-editable/template.hbs"
    }
  });

  _exports.default = _default;
});