define("osf-components/components/registries/registration-form-navigation-dropdown/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "Dropdown": "_Dropdown_w1buen",
    "MenuTrigger": "_MenuTrigger_w1buen",
    "ToggleNav__button": "_ToggleNav__button_w1buen",
    "NavigationMenu": "_NavigationMenu_w1buen",
    "Header": "_Header_w1buen",
    "HeaderText": "_HeaderText_w1buen",
    "Anchors": "_Anchors_w1buen",
    "page-heading": "_page-heading_w1buen",
    "section-heading": "_section-heading_w1buen",
    "subsection-heading": "_subsection-heading_w1buen",
    "question-label": "_question-label_w1buen"
  };
  _exports.default = _default;
});