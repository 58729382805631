define("osf-components/components/files/selected-list/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "selected-list": "_selected-list_wgw7uc",
    "selected-item": "_selected-item_wgw7uc",
    "unselect": "_unselect_wgw7uc"
  };
  _exports.default = _default;
});