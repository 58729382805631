define("osf-components/components/files/item/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "file-row": "_file-row_1e8anf",
    "filename": "_filename_1e8anf",
    "indent": "_indent_1e8anf",
    "name-container": "_name-container_1e8anf",
    "date-modified": "_date-modified_1e8anf"
  };
  _exports.default = _default;
});