define("osf-components/components/files/widget/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nwgplQDc",
    "block": "{\"symbols\":[\"filesManager\",\"&attrs\",\"@node\",\"@onSelectFile\",\"@onAddFile\"],\"statements\":[[7,\"div\",false],[13,2],[8],[0,\"\\n    \"],[5,\"files/manager\",[],[[\"@node\",\"@onSelectFile\",\"@onAddFile\"],[[23,3,[]],[23,4,[]],[23,5,[]]]],{\"statements\":[[0,\"\\n        \"],[5,\"files/browse\",[],[[\"@filesManager\"],[[23,1,[]]]]],[0,\"\\n    \"]],\"parameters\":[1]}],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/files/widget/template.hbs"
    }
  });

  _exports.default = _default;
});