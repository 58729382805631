define("osf-components/components/registration-report-export-button/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "Modal": "_Modal_14g1m1",
    "Modal__title": "_Modal__title_14g1m1",
    "Export__description": "_Export__description_14g1m1"
  };
  _exports.default = _default;
});