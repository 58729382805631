define("osf-components/components/file-browser/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "file-browser": "_file-browser_1qi2e2",
    "dropzone-area": "_dropzone-area_1qi2e2",
    "dz-clickable": "_dz-clickable_1qi2e2",
    "upload-drop": "_upload-drop_1qi2e2",
    "upload-text": "_upload-text_1qi2e2",
    "file-placeholder": "_file-placeholder_1qi2e2",
    "file-placeholder-content": "_file-placeholder-content_1qi2e2",
    "file-placeholder-text": "_file-placeholder-text_1qi2e2",
    "breadcrumb": "_breadcrumb_1qi2e2",
    "action-button": "_action-button_1qi2e2",
    "actions-header": "_actions-header_1qi2e2",
    "small-display": "_small-display_1qi2e2",
    "column-labels-header": "_column-labels-header_1qi2e2",
    "file-browser-list": "_file-browser-list_1qi2e2",
    "items": "_items_1qi2e2",
    "shade": "_shade_1qi2e2",
    "transparent": "_transparent_1qi2e2",
    "file-browser-header": "_file-browser-header_1qi2e2",
    "header": "_header_1qi2e2",
    "sortable-column": "_sortable-column_1qi2e2",
    "link-column": "_link-column_1qi2e2",
    "link-url": "_link-url_1qi2e2",
    "file-row-item": "_file-row-item_1qi2e2",
    "file-row-col": "_file-row-col_1qi2e2",
    "upload-file-header": "_upload-file-header_1qi2e2",
    "selected-item-line": "_selected-item-line_1qi2e2"
  };
  _exports.default = _default;
});