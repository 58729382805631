define("osf-components/components/scheduled-banner/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "P2ALnWSH",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,0,[\"banner\"]]],null,{\"statements\":[[4,\"scheduled-banner/x-maybe-link\",null,[[\"href\"],[[23,0,[\"banner\",\"link\"]]]],{\"statements\":[[0,\"        \"],[7,\"div\",true],[11,\"style\",[23,0,[\"bannerWrapperStyle\"]]],[8],[0,\"\\n            \"],[7,\"div\",true],[10,\"class\",\"container\"],[8],[0,\"\\n                \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n                    \"],[7,\"div\",true],[10,\"class\",\"col-sm-10 col-sm-offset-1 col-lg-8 col-lg-offset-2 hidden-xs\"],[8],[0,\"\\n                        \"],[7,\"img\",true],[11,\"class\",[29,[\"img-responsive \",[28,\"local-class\",[\"Image\"],[[\"from\"],[\"osf-components/components/scheduled-banner/styles\"]]]]]],[11,\"src\",[23,0,[\"banner\",\"links\",\"default_photo\"]]],[11,\"alt\",[23,0,[\"banner\",\"defaultAltText\"]]],[8],[9],[0,\"\\n                    \"],[9],[0,\"\\n                    \"],[7,\"div\",true],[10,\"class\",\"col-xs-12 hidden-sm hidden-md hidden-lg hidden-xl\"],[8],[0,\"\\n                        \"],[7,\"img\",true],[11,\"class\",[29,[\"img-responsive \",[28,\"local-class\",[\"Image\"],[[\"from\"],[\"osf-components/components/scheduled-banner/styles\"]]]]]],[11,\"src\",[23,0,[\"banner\",\"links\",\"mobile_photo\"]]],[11,\"alt\",[23,0,[\"banner\",\"mobileAltText\"]]],[8],[9],[0,\"\\n                    \"],[9],[0,\"\\n                \"],[9],[0,\"\\n            \"],[9],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/scheduled-banner/template.hbs"
    }
  });

  _exports.default = _default;
});