define("osf-components/components/file-editor/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "FileEditor__panel-heading": "_FileEditor__panel-heading_zs9x6q",
    "FileEditor__panel-title": "_FileEditor__panel-title_zs9x6q",
    "FileEditor__text-editor": "_FileEditor__text-editor_zs9x6q"
  };
  _exports.default = _default;
});