define("osf-components/components/editable-field/tags-manager/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TDt2nAx9",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[28,\"hash\",null,[[\"save\",\"cancel\",\"addTag\",\"removeTag\",\"clickTag\",\"tags\",\"registration\",\"fieldIsEmpty\",\"emptyFieldText\",\"startEditing\",\"inEditMode\",\"userCanEdit\",\"shouldShowField\",\"isSaving\"],[[28,\"perform\",[[23,0,[\"save\"]]],null],[28,\"action\",[[23,0,[]],[23,0,[\"cancel\"]]],null],[28,\"action\",[[23,0,[]],[23,0,[\"addTag\"]]],null],[28,\"action\",[[23,0,[]],[23,0,[\"removeTag\"]]],null],[28,\"action\",[[23,0,[]],[23,0,[\"clickTag\"]]],null],[23,0,[\"currentTags\"]],[23,0,[\"registration\"]],[23,0,[\"fieldIsEmpty\"]],[28,\"t\",[\"osf-components.tags-widget.no_tags\"],null],[28,\"action\",[[23,0,[]],[23,0,[\"startEditing\"]]],null],[23,0,[\"inEditMode\"]],[23,0,[\"userCanEdit\"]],[23,0,[\"shouldShowField\"]],[23,0,[\"save\",\"isRunning\"]]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/editable-field/tags-manager/template.hbs"
    }
  });

  _exports.default = _default;
});