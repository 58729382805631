define("osf-components/components/file-icon/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2g8XXtUP",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"fa-icon\",[[23,0,[\"iconName\"]]],[[\"fixedWidth\"],[true]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/file-icon/template.hbs"
    }
  });

  _exports.default = _default;
});