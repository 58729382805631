define("osf-components/components/osf-layout/left-nav/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PSEVt6SR",
    "block": "{\"symbols\":[\"leftGutter\",\"sideNav\",\"@gutters\",\"@toggleSidenav\",\"&default\"],\"statements\":[[6,[23,3,[\"left-gutter\"]],[],[[],[]],{\"statements\":[[0,\"\\n    \"],[5,\"osf-layout/registries-side-nav\",[[12,\"inert\",[28,\"and\",[[28,\"not-eq\",[[23,1,[\"mode\"]],\"column\"],null],[23,1,[\"closed\"]]],null]]],[[\"@onLinkClicked\"],[[23,4,[]]]],{\"statements\":[[0,\"\\n        \"],[14,5,[[28,\"hash\",null,[[\"link\",\"leftGutterMode\",\"toggleSidenav\"],[[23,2,[\"link\"]],[23,1,[\"mode\"]],[23,4,[]]]]]]],[0,\"\\n    \"]],\"parameters\":[2]}],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/osf-layout/left-nav/template.hbs"
    }
  });

  _exports.default = _default;
});