define("osf-components/components/form-controls/radio-button-group/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YK+Lk1f5",
    "block": "{\"symbols\":[\"option\",\"uniqueId\",\"@changeset\",\"@valuePath\",\"@helpTextMapping\",\"&attrs\",\"@shouldShowMessages\"],\"statements\":[[7,\"div\",false],[12,\"class\",[29,[[28,\"local-class\",[\"RadioButtonGroup\"],[[\"from\"],[\"osf-components/components/form-controls/radio-button-group/styles\"]]]]]],[12,\"data-test-radio-button-group\",\"\"],[13,6],[8],[0,\"\\n\"],[4,\"each\",[[23,0,[\"options\"]]],null,{\"statements\":[[4,\"let\",[[28,\"unique-id\",[\"radio\",[23,1,[]]],null]],null,{\"statements\":[[0,\"            \"],[7,\"div\",true],[11,\"class\",[29,[[28,\"local-class\",[\"RadioButton\"],[[\"from\"],[\"osf-components/components/form-controls/radio-button-group/styles\"]]]]]],[10,\"data-test-radio-input\",\"\"],[8],[0,\"\\n                \"],[7,\"input\",true],[11,\"id\",[23,2,[]]],[11,\"name\",[23,4,[]]],[11,\"value\",[23,1,[]]],[11,\"onclick\",[28,\"action\",[[23,0,[]],[23,0,[\"updateChangeset\"]],[23,1,[]]],null]],[11,\"disabled\",[23,0,[\"disabled\"]]],[11,\"checked\",[28,\"if\",[[28,\"eq\",[[28,\"get\",[[23,3,[]],[23,4,[]]],null],[23,1,[]]],null],true,false],null]],[10,\"type\",\"radio\"],[8],[9],[0,\"\\n                \"],[7,\"label\",true],[11,\"class\",[29,[[28,\"local-class\",[\"RadioLabel\"],[[\"from\"],[\"osf-components/components/form-controls/radio-button-group/styles\"]]]]]],[11,\"for\",[23,2,[]]],[8],[0,\"\\n                    \"],[1,[23,1,[]],false],[0,\"\\n                    \"],[5,\"registries/schema-block-renderer/helper-text-icon\",[],[[\"@helpText\"],[[28,\"get\",[[23,5,[]],[23,1,[]]],null]]]],[0,\"\\n                \"],[9],[0,\"\\n            \"],[9],[0,\"\\n\"]],\"parameters\":[2]},null]],\"parameters\":[1]},null],[4,\"if\",[[23,7,[]]],null,{\"statements\":[[0,\"        \"],[5,\"validation-errors\",[],[[\"@changeset\",\"@key\"],[[23,3,[]],[23,4,[]]]]],[0,\"\\n\"]],\"parameters\":[]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/form-controls/radio-button-group/template.hbs"
    }
  });

  _exports.default = _default;
});