define("osf-components/components/osf-layout/left-column/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WwpAWhz+",
    "block": "{\"symbols\":[\"leftGutter\",\"@gutters\",\"&attrs\",\"@toggleSidenav\",\"&default\"],\"statements\":[[6,[23,2,[\"left-gutter\"]],[[13,3]],[[],[]],{\"statements\":[[0,\"\\n    \"],[14,5,[[28,\"hash\",null,[[\"leftNav\",\"leftGutterMode\",\"toggleSidenav\"],[[28,\"component\",[\"side-nav\"],[[\"onLinkClicked\"],[[23,4,[]]]]],[23,1,[\"mode\"]],[23,4,[]]]]]]],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/osf-layout/left-column/template.hbs"
    }
  });

  _exports.default = _default;
});