define("osf-components/components/side-nav/x-link/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "Link": "_Link_vziva5",
    "Count": "_Count_vziva5",
    "Label": "_Label_vziva5",
    "Icon": "_Icon_vziva5",
    "Collapsed": "_Collapsed_vziva5",
    "LabelWrapper": "_LabelWrapper_vziva5",
    "Toggle": "_Toggle_vziva5"
  };
  _exports.default = _default;
});