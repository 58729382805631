define("osf-components/components/subjects/display/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ax8t568/",
    "block": "{\"symbols\":[\"subject\",\"@removeSubject\",\"@subjects\"],\"statements\":[[4,\"if\",[[23,0,[\"isLoading\"]]],null,{\"statements\":[[0,\"    \"],[5,\"loading-indicator\",[],[[\"@dark\"],[true]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"ul\",true],[11,\"class\",[29,[[28,\"local-class\",[\"Subjects\"],[[\"from\"],[\"osf-components/components/subjects/display/styles\"]]]]]],[10,\"data-analytics-scope\",\"Display\"],[8],[0,\"\\n\"],[4,\"each\",[[23,3,[]]],null,{\"statements\":[[0,\"            \"],[7,\"li\",true],[11,\"class\",[29,[[28,\"local-class\",[\"Subject\"],[[\"from\"],[\"osf-components/components/subjects/display/styles\"]]]]]],[8],[0,\"\\n                \"],[1,[23,1,[\"text\"]],false],[0,\"\\n\\n\"],[4,\"if\",[[23,2,[]]],null,{\"statements\":[[0,\"                    \"],[5,\"osf-button\",[[12,\"class\",[29,[[28,\"local-class\",[\"RemoveButton\"],[[\"from\"],[\"osf-components/components/subjects/display/styles\"]]]]]],[12,\"data-analytics-name\",\"Remove\"],[12,\"data-analytics-extra\",[23,1,[\"text\"]]],[12,\"role\",\"button\"],[12,\"aria-label\",[28,\"t\",[\"osf-components.subjects.display.remove_subject\"],null]]],[[\"@type\",\"@onClick\"],[\"link\",[28,\"action\",[[23,0,[]],[23,2,[]],[23,1,[]]],null]]],{\"statements\":[[0,\"\\n                        \"],[5,\"fa-icon\",[],[[\"@icon\",\"@fixedWidth\"],[\"times\",true]]],[0,\"\\n                    \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"            \"],[9],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[0,\"            \"],[7,\"span\",true],[11,\"class\",[29,[[28,\"local-class\",[\"Placeholder\"],[[\"from\"],[\"osf-components/components/subjects/display/styles\"]]]]]],[8],[0,\"\\n                \"],[1,[28,\"t\",[\"osf-components.subjects.display.placeholder\"],null],false],[0,\"\\n            \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/subjects/display/template.hbs"
    }
  });

  _exports.default = _default;
});