define("osf-components/components/registries/schema-block-renderer/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "Heading": "_Heading_t890a9",
    "Element": "_Element_t890a9"
  };
  _exports.default = _default;
});