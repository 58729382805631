define("osf-components/components/form-controls/power-select/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "i3doxtR9",
    "block": "{\"symbols\":[\"inputElementId\",\"option\",\"@options\",\"@valuePath\",\"@changeset\",\"@shouldShowMessages\",\"@label\",\"&attrs\",\"@searchField\",\"@searchEnabled\",\"@disabled\",\"&default\"],\"statements\":[[1,[28,\"assert\",[\"FormControls::Select - @options are required\",[23,3,[]]],null],false],[0,\"\\n\"],[1,[28,\"assert\",[\"FormControls::Select - @valuePath are required\",[23,4,[]]],null],false],[0,\"\\n\"],[1,[28,\"assert\",[\"FormControls::Select - @changeseet are required\",[23,5,[]]],null],false],[0,\"\\n\"],[5,\"form-controls/wrapper\",[],[[\"@shouldShowMessages\",\"@label\",\"@changeset\",\"@valuePath\"],[[23,6,[]],[23,7,[]],[23,5,[]],[23,4,[]]]],{\"statements\":[[0,\"\\n    \"],[7,\"div\",false],[12,\"data-test-power-select-dropdown\",\"\"],[12,\"id\",[23,1,[]]],[13,8],[8],[0,\"\\n        \"],[5,\"power-select\",[[12,\"id\",[23,1,[]]]],[[\"@options\",\"@onchange\",\"@searchField\",\"@searchEnabled\",\"@selected\",\"@disabled\"],[[23,0,[\"options\"]],[23,0,[\"updateChangeset\"]],[23,9,[]],[23,10,[]],[28,\"or\",[[28,\"get\",[[23,5,[]],[23,0,[\"valuePath\"]]],null],null],null],[23,11,[]]]],{\"statements\":[[0,\"\\n            \"],[7,\"span\",true],[11,\"aria-label\",[23,2,[]]],[11,\"data-test-option\",[23,2,[]]],[8],[0,\"\\n                \"],[14,12,[[23,2,[]]]],[0,\"\\n            \"],[9],[0,\"\\n        \"]],\"parameters\":[2]}],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[1]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/form-controls/power-select/template.hbs"
    }
  });

  _exports.default = _default;
});