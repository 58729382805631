define("osf-components/components/status-banner/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tg06ZQMJ",
    "block": "{\"symbols\":[\"stat\"],\"statements\":[[4,\"each\",[[23,0,[\"messages\"]]],null,{\"statements\":[[0,\"    \"],[5,\"bs-alert\",[],[[\"@type\",\"@dismissible\",\"@classNames\"],[[23,1,[\"class\"]],[23,1,[\"dismiss\"]],[28,\"if\",[[23,1,[\"jumbo\"]],[28,\"local-class\",[\"StatusBanner__jumbo\"],[[\"from\"],[\"osf-components/components/status-banner/styles\"]]]],null]]],{\"statements\":[[0,\"\\n        \"],[7,\"div\",true],[11,\"class\",[29,[[28,\"if\",[[23,1,[\"jumbo\"]],\"jumbotron\"],null]]]],[8],[0,\"\\n            \"],[7,\"p\",true],[11,\"data-test-status-message\",[23,1,[\"id\"]]],[8],[1,[28,\"t\",[[23,1,[\"id\"]]],[[\"extra\",\"htmlSafe\"],[[23,1,[\"extra\"]],true]]],false],[9],[0,\"\\n        \"],[9],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/status-banner/template.hbs"
    }
  });

  _exports.default = _default;
});