define("osf-components/components/contributor-list/contributor/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Bs/nq10h",
    "block": "{\"symbols\":[\"@contributor\"],\"statements\":[[4,\"if\",[[23,0,[\"contributorLink\"]]],null,{\"statements\":[[0,\"    \"],[7,\"a\",true],[11,\"data-test-contributor-name\",[23,1,[\"users\",\"id\"]]],[10,\"data-analytics-name\",\"Contributor name\"],[11,\"href\",[23,0,[\"contributorLink\"]]],[8],[1,[23,0,[\"contributorName\"]],false],[9]],\"parameters\":[]},{\"statements\":[[7,\"span\",true],[11,\"data-test-contributor-name\",[23,1,[\"users\",\"id\"]]],[8],[1,[23,0,[\"contributorName\"]],false],[9]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/contributor-list/contributor/template.hbs"
    }
  });

  _exports.default = _default;
});