define("osf-components/components/registries/review-metadata-renderer/label-display/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UdJ9PBjn",
    "block": "{\"symbols\":[\"@changeset\",\"@field\",\"@draftRegistration\",\"@fieldText\",\"&attrs\",\"&default\",\"@hideError\"],\"statements\":[[1,[28,\"assert\",[\"Registries::ReviewMetadataRenderer::LabelDisplay requires a draftRegistration\",[23,3,[]]],null],false],[0,\"\\n\"],[1,[28,\"assert\",[\"Registries::ReviewMetadataRenderer::LabelDisplay requires a field\",[23,2,[]]],null],false],[0,\"\\n\"],[1,[28,\"assert\",[\"Registries::ReviewMetadataRenderer::LabelDisplay requires a changeset\",[23,1,[]]],null],false],[0,\"\\n\"],[7,\"p\",true],[11,\"class\",[29,[[28,\"local-class\",[\"DisplayText\"],[[\"from\"],[\"osf-components/components/registries/review-metadata-renderer/label-display/styles\"]]]]]],[11,\"data-test-review-label\",[23,2,[]]],[11,\"id\",[23,2,[]]],[8],[0,\"\\n    \"],[1,[28,\"t\",[[28,\"concat\",[\"registries.registration_metadata.\",[23,2,[]]],null]],null],false],[0,\"\\n    \"],[5,\"osf-link\",[[12,\"aria-label\",[28,\"t\",[\"registries.registration_metadata.edit_field\"],[[\"field\"],[[28,\"if\",[[23,4,[]],[23,4,[]],[23,2,[]]],null]]]]]],[[\"@route\",\"@models\",\"@fragment\"],[\"registries.drafts.draft.metadata\",[28,\"array\",[[23,3,[\"id\"]]],null],[23,2,[]]]],{\"statements\":[[0,\"\\n        \"],[5,\"fa-icon\",[],[[\"@icon\"],[\"edit\"]]],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"p\",false],[12,\"class\",[29,[[28,\"local-class\",[\"ResponseValue\"],[[\"from\"],[\"osf-components/components/registries/review-metadata-renderer/label-display/styles\"]]]]]],[12,\"data-test-review-response\",[23,2,[]]],[13,5],[8],[14,6],[9],[0,\"\\n\"],[4,\"unless\",[[23,7,[]]],null,{\"statements\":[[0,\"    \"],[5,\"validation-errors\",[[12,\"class\",[29,[[28,\"local-class\",[\"ValidationErrors\"],[[\"from\"],[\"osf-components/components/registries/review-metadata-renderer/label-display/styles\"]]]]]]],[[\"@changeset\",\"@key\"],[[23,1,[]],[23,2,[]]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/registries/review-metadata-renderer/label-display/template.hbs"
    }
  });

  _exports.default = _default;
});