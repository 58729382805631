define("osf-components/components/validated-input/date/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NUvOawXs",
    "block": "{\"symbols\":[\"inputElementId\",\"@minDate\",\"@maxDate\",\"@disabled\"],\"statements\":[[4,\"validated-input/x-input-wrapper\",null,[[\"model\",\"changeset\",\"errors\",\"label\",\"valuePath\",\"validationStatus\"],[[23,0,[\"model\"]],[23,0,[\"changeset\"]],[23,0,[\"errors\"]],[23,0,[\"label\"]],[23,0,[\"valuePath\"]],[23,0,[\"validationStatus\"]]]],{\"statements\":[[0,\"    \"],[5,\"ember-flatpickr\",[[12,\"class\",\"form-control\"],[12,\"placeholder\",[23,0,[\"placeholder\"]]],[12,\"id\",[23,1,[]]],[12,\"aria-label\",[23,0,[\"ariaLabel\"]]]],[[\"@onChange\",\"@minDate\",\"@maxDate\",\"@disabled\"],[[28,\"action\",[[23,0,[]],[23,0,[\"onChange\"]]],null],[23,2,[]],[23,3,[]],[23,4,[]]]]],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/validated-input/date/template.hbs"
    }
  });

  _exports.default = _default;
});