define("osf-components/components/registries/hierarchical-list/item-manager/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "C8j6hQU2",
    "block": "{\"symbols\":[\"@listManager\",\"&default\"],\"statements\":[[14,2,[[28,\"hash\",null,[[\"item\",\"children\",\"toggleShowChildren\",\"shouldShowChildren\",\"listManager\",\"onChange\",\"itemChecked\",\"isRoot\"],[[23,0,[\"item\"]],[23,0,[\"children\"]],[28,\"action\",[[23,0,[]],[23,0,[\"toggleShowChildren\"]]],null],[23,0,[\"shouldShowChildren\"]],[23,1,[]],[28,\"action\",[[23,0,[]],[23,0,[\"onChange\"]]],null],[23,0,[\"itemChecked\"]],[23,0,[\"isRoot\"]]]]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/registries/hierarchical-list/item-manager/template.hbs"
    }
  });

  _exports.default = _default;
});