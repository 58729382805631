define("osf-components/components/file-renderer/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1mELLXVz",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,0,[\"download\"]]],null,{\"statements\":[[4,\"if\",[[23,0,[\"isLoading\"]]],null,{\"statements\":[[0,\"        \"],[5,\"loading-indicator\",[],[[\"@dark\"],[true]]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[7,\"iframe\",true],[11,\"src\",[23,0,[\"mfrUrl\"]]],[11,\"width\",[23,0,[\"width\"]]],[11,\"title\",[28,\"t\",[\"file_detail.mfr_iframe_title\"],null]],[11,\"height\",[23,0,[\"height\"]]],[10,\"scrolling\",\"yes\"],[10,\"marginheight\",\"0\"],[10,\"frameborder\",\"0\"],[11,\"allowfullscreen\",[23,0,[\"allowfullscreen\"]]],[11,\"hidden\",[23,0,[\"isLoading\"]]],[11,\"onload\",[28,\"action\",[[23,0,[]],[23,0,[\"loaded\"]]],null]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/file-renderer/template.hbs"
    }
  });

  _exports.default = _default;
});