define("osf-components/components/new-project-modal/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "NewProject__label": "_NewProject__label_fz56y2",
    "NewProject__institution-selector": "_NewProject__institution-selector_fz56y2",
    "NewProject__institution-item": "_NewProject__institution-item_fz56y2",
    "NewProject__institution-button": "_NewProject__institution-button_fz56y2",
    "NewProject__institution-img-not-selected": "_NewProject__institution-img-not-selected_fz56y2",
    "NewProject__placeholder": "_NewProject__placeholder_fz56y2",
    "NewProject__modal": "_NewProject__modal_fz56y2",
    "NewProject__more": "_NewProject__more_fz56y2"
  };
  _exports.default = _default;
});