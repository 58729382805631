define("osf-components/components/registries/schema-block-renderer/editable/rdm/e-rad-award-field-input/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "I1Pd1c01",
    "block": "{\"symbols\":[\"form\",\"select\",\"@changeset\",\"@disabled\",\"@shouldShowMessages\",\"&attrs\",\"@uniqueID\",\"@schemaBlock\"],\"statements\":[[5,\"registries/schema-block-renderer/editable/base\",[],[[\"@helpText\"],[[23,0,[\"schemaBlock\",\"helpText\"]]]],{\"statements\":[[0,\"\\n    \"],[5,\"form-controls\",[[13,6]],[[\"@changeset\",\"@disabled\",\"@shouldShowMessages\"],[[23,3,[]],[23,4,[]],[23,5,[]]]],{\"statements\":[[0,\"\\n        \"],[6,[23,1,[\"select\"]],[[12,\"data-test-e-rad-award-field-input\",\"\"]],[[\"@id\",\"@valuePath\",\"@options\",\"@onchange\"],[[23,7,[]],[23,8,[\"registrationResponseKey\"]],[23,0,[\"optionBlockValues\"]],[28,\"action\",[[23,0,[]],[23,0,[\"onChange\"]]],null]]],{\"statements\":[[0,\"\\n            \"],[1,[23,2,[]],false],[0,\"\\n        \"]],\"parameters\":[2]}],[0,\"\\n    \"]],\"parameters\":[1]}],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/registries/schema-block-renderer/editable/rdm/e-rad-award-field-input/template.hbs"
    }
  });

  _exports.default = _default;
});