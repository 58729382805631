define("osf-components/components/paginated-list/x-header/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JoG9cU9F",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[4,\"if\",[[23,0,[\"isTable\"]]],null,{\"statements\":[[0,\"    \"],[7,\"thead\",false],[13,1],[8],[0,\"\\n        \"],[14,2],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"li\",false],[13,1],[8],[0,\"\\n        \"],[14,2],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/paginated-list/x-header/template.hbs"
    }
  });

  _exports.default = _default;
});