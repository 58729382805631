define("osf-components/components/tos-consent-banner/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pwxjKTn4",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"data-analytics-scope\",\"ToS consent banner\"],[10,\"class\",\"container\"],[8],[0,\"\\n    \"],[5,\"bs-alert\",[],[[\"@type\",\"@dismissible\",\"@visible\",\"@onDismiss\"],[\"default\",true,[23,0,[\"currentUser\",\"showTosConsentBanner\"]],[28,\"action\",[[23,0,[]],[23,0,[\"dismiss\"]]],null]]],{\"statements\":[[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"jumbotron\"],[8],[0,\"\\n            \"],[7,\"p\",true],[8],[9],[0,\"\\n            \"],[7,\"div\",true],[8],[0,\"\\n                \"],[7,\"h4\",true],[8],[0,\"\\n                    \"],[1,[28,\"t\",[\"tos_consent.paragraph\"],[[\"link1\",\"link2\",\"htmlSafe\"],[[23,0,[\"termsLink\"]],[23,0,[\"privacyPolicyLink\"]],true]]],false],[0,\"\\n                \"],[9],[0,\"\\n                \"],[7,\"h5\",true],[8],[0,\"\\n\"],[4,\"validated-input/checkbox\",null,[[\"model\",\"valuePath\",\"shouldShowMessages\"],[[23,0,[\"currentUser\",\"user\"]],\"acceptedTermsOfService\",[23,0,[\"didValidate\"]]]],{\"statements\":[[0,\"                        \"],[1,[28,\"t\",[\"tos_consent.have_read_and_agree\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"                \"],[9],[0,\"\\n            \"],[9],[0,\"\\n            \"],[5,\"osf-button\",[[12,\"data-analytics-name\",\"Continue\"]],[[\"@type\",\"@buttonType\",\"@onClick\"],[\"primary\",\"submit\",[28,\"action\",[[23,0,[]],[28,\"perform\",[[23,0,[\"saveUser\"]]],null]],null]]],{\"statements\":[[0,\"\\n                \"],[1,[28,\"t\",[\"tos_consent.continue\"],null],false],[0,\"\\n            \"]],\"parameters\":[]}],[0,\"\\n            \"],[7,\"p\",true],[8],[9],[0,\"\\n        \"],[9],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/tos-consent-banner/template.hbs"
    }
  });

  _exports.default = _default;
});