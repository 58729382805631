define("osf-components/components/institution-carousel/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "InstitutionCarousel__control": "_InstitutionCarousel__control_16pdz4 carousel-control",
    "left": "_left_16pdz4 left",
    "right": "_right_16pdz4 right",
    "InstitutionCarousel__item": "_InstitutionCarousel__item_16pdz4"
  };
  _exports.default = _default;
});