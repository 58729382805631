define("osf-components/components/ancestry-display/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kS92heAw",
    "block": "{\"symbols\":[\"ancestor\",\"index\",\"&attrs\"],\"statements\":[[7,\"span\",false],[12,\"class\",[29,[[28,\"local-class\",[\"Titles\"],[[\"from\"],[\"osf-components/components/ancestry-display/styles\"]]]]]],[13,3],[8],[0,\"\\n\"],[4,\"each\",[[23,0,[\"ancestry\"]]],null,{\"statements\":[[0,\"        \"],[7,\"span\",true],[11,\"class\",[29,[[28,\"local-class\",[\"AncestorTitle\"],[[\"from\"],[\"osf-components/components/ancestry-display/styles\"]]]]]],[8],[0,\"\\n\"],[4,\"if\",[[28,\"and\",[[23,0,[\"useLinks\"]],[23,1,[\"id\"]]],null]],null,{\"statements\":[[0,\"                \"],[5,\"osf-link\",[[12,\"data-test-ancestor-title\",[23,2,[]]]],[[\"@route\",\"@models\"],[\"resolve-guid\",[28,\"array\",[[23,1,[\"id\"]]],null]]],{\"statements\":[[0,\"\\n                    \"],[1,[23,1,[\"title\"]],false],[0,\"\\n                \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"                \"],[1,[23,1,[\"title\"]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"        \"],[9],[0,\"\\n        \"],[7,\"span\",true],[11,\"class\",[29,[[28,\"local-class\",[\"delimiter\"],[[\"from\"],[\"osf-components/components/ancestry-display/styles\"]]]]]],[8],[1,[23,0,[\"delimiter\"]],false],[9],[0,\"\\n\"]],\"parameters\":[1,2]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/ancestry-display/template.hbs"
    }
  });

  _exports.default = _default;
});