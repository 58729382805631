define("osf-components/components/scheduled-banner/x-maybe-link/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zi4KrE5/",
    "block": "{\"symbols\":[\"&default\",\"@banner\",\"@href\"],\"statements\":[[4,\"if\",[[23,3,[]]],null,{\"statements\":[[0,\"    \"],[5,\"osf-link\",[[12,\"target\",\"_blank\"],[12,\"rel\",\"noopener noreferrer\"],[12,\"data-analytics-name\",[28,\"concat\",[\"Banner - \",[23,2,[\"name\"]]],null]]],[[\"@href\"],[[23,3,[]]]],{\"statements\":[[0,\"\\n        \"],[14,1],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[14,1],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/scheduled-banner/x-maybe-link/template.hbs"
    }
  });

  _exports.default = _default;
});