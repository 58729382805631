define("osf-components/components/registries/review-metadata-renderer/label-display/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "ResponseValue": "_ResponseValue_is8mg0 _Element_t890a9",
    "ValidationErrors": "_ValidationErrors_is8mg0 _Element_t890a9",
    "DisplayText": "_DisplayText_is8mg0"
  };
  _exports.default = _default;
});