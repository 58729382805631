define("osf-components/components/subjects/browse/animated-expand/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6o3CkHaG",
    "block": "{\"symbols\":[\"@isExpanded\",\"&default\"],\"statements\":[[5,\"animated-container\",[[12,\"class\",[29,[[28,\"local-class\",[\"ExpandyContainer\"],[[\"from\"],[\"osf-components/components/subjects/browse/animated-expand/styles\"]]]]]]],[[],[]],{\"statements\":[[0,\"\\n    \"],[5,\"animated-if\",[],[[\"@predicate\",\"@use\",\"@duration\"],[[23,1,[]],[23,0,[\"expandListTransition\"]],\"100\"]],{\"statements\":[[0,\"\\n        \"],[14,2],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/subjects/browse/animated-expand/template.hbs"
    }
  });

  _exports.default = _default;
});