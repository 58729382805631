define("osf-components/components/tags-widget/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "TagsWidget": "_TagsWidget_1y0zmy"
  };
  _exports.default = _default;
});