define("osf-components/components/contributors/list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "abY179PM",
    "block": "{\"symbols\":[\"Card\",\"contributor\",\"@contributorsManager\",\"&attrs\",\"@widgetMode\"],\"statements\":[[7,\"ul\",false],[12,\"class\",[29,[[28,\"local-class\",[\"ContributorList\"],[[\"from\"],[\"osf-components/components/contributors/list/styles\"]]]]]],[13,4],[8],[0,\"\\n\"],[4,\"let\",[[28,\"get\",[[28,\"hash\",null,[[\"readonly\"],[[28,\"component\",[\"contributors/card/readonly\"],null]]]],[23,5,[]]],null]],null,{\"statements\":[[4,\"each\",[[23,3,[\"contributors\"]]],null,{\"statements\":[[0,\"            \"],[6,[23,1,[]],[],[[\"@contributor\"],[[23,2,[]]]]],[0,\"\\n\"]],\"parameters\":[2]},null]],\"parameters\":[1]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/contributors/list/template.hbs"
    }
  });

  _exports.default = _default;
});