define("osf-components/components/validated-input/text/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "31Eb3eWq",
    "block": "{\"symbols\":[\"inputElementId\",\"@uniqueID\",\"@valuePath\",\"@onKeyUp\",\"@onChange\"],\"statements\":[[4,\"validated-input/x-input-wrapper\",null,[[\"model\",\"changeset\",\"errors\",\"label\",\"valuePath\",\"validationStatus\"],[[23,0,[\"model\"]],[23,0,[\"changeset\"]],[23,0,[\"errors\"]],[23,0,[\"label\"]],[23,0,[\"valuePath\"]],[23,0,[\"validationStatus\"]]]],{\"statements\":[[0,\"    \"],[5,\"input\",[[12,\"aria-label\",[23,0,[\"ariaLabel\"]]]],[[\"@id\",\"@type\",\"@value\",\"@placeholder\",\"@class\",\"@name\",\"@keyUp\",\"@change\",\"@disabled\"],[[28,\"or\",[[23,2,[]],[23,1,[]]],null],[28,\"if\",[[23,0,[\"password\"]],\"password\",\"text\"],null],[23,0,[\"value\"]],[23,0,[\"_placeholder\"]],\"form-control\",[23,3,[]],[23,4,[]],[23,5,[]],[23,0,[\"disabled\"]]]]],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/validated-input/text/template.hbs"
    }
  });

  _exports.default = _default;
});