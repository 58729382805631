define("osf-components/components/contributors/widget/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "Container": "_Container_1mm32y",
    "Heading": "_Heading_1mm32y",
    "HeadingTitle": "_HeadingTitle_1mm32y"
  };
  _exports.default = _default;
});