define("osf-components/components/osf-layout/main-column/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "J3rdfSrf",
    "block": "{\"symbols\":[\"@gutters\",\"&attrs\",\"&default\"],\"statements\":[[6,[23,1,[\"body\"]],[[12,\"class\",[29,[[28,\"local-class\",[\"Main\"],[[\"from\"],[\"osf-components/components/osf-layout/main-column/styles\"]]]]]],[13,2]],[[],[]],{\"statements\":[[0,\"\\n    \"],[14,3,[[28,\"hash\",null,[[\"main\"],[[28,\"component\",[[28,\"-element\",[\"div\"],null]],[[\"tagName\"],[\"div\"]]]]]]]],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/osf-layout/main-column/template.hbs"
    }
  });

  _exports.default = _default;
});