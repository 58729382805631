define("osf-components/components/success-icon/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lCwg7Q+G",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[[28,\"local-class\",[\"AnimatedIcon AnimatedIcon--success\"],[[\"from\"],[\"osf-components/components/success-icon/styles\"]]]]]],[8],[0,\"\\n    \"],[7,\"div\",true],[11,\"class\",[29,[[28,\"local-class\",[\"AnimatedIcon__circular-line AnimatedIcon__circular-line-left\"],[[\"from\"],[\"osf-components/components/success-icon/styles\"]]]]]],[8],[9],[0,\"\\n    \"],[7,\"span\",true],[11,\"class\",[29,[[28,\"local-class\",[\"AnimatedIcon__line AnimatedIcon__line-tip\"],[[\"from\"],[\"osf-components/components/success-icon/styles\"]]]]]],[8],[9],[0,\" \\n    \"],[7,\"span\",true],[11,\"class\",[29,[[28,\"local-class\",[\"AnimatedIcon__line AnimatedIcon__line-long\"],[[\"from\"],[\"osf-components/components/success-icon/styles\"]]]]]],[8],[9],[0,\"\\n    \"],[7,\"div\",true],[11,\"class\",[29,[[28,\"local-class\",[\"AnimatedIcon__ring\"],[[\"from\"],[\"osf-components/components/success-icon/styles\"]]]]]],[8],[9],[0,\"\\n    \"],[7,\"div\",true],[11,\"class\",[29,[[28,\"local-class\",[\"AnimatedIcon__fix\"],[[\"from\"],[\"osf-components/components/success-icon/styles\"]]]]]],[8],[9],[0,\"\\n    \"],[7,\"div\",true],[11,\"class\",[29,[[28,\"local-class\",[\"AnimatedIcon__circular-line AnimatedIcon__circular-line-right\"],[[\"from\"],[\"osf-components/components/success-icon/styles\"]]]]]],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/success-icon/template.hbs"
    }
  });

  _exports.default = _default;
});