define("osf-components/components/osf-navbar/x-links/hyper-link/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PC0pO2mX",
    "block": "{\"symbols\":[\"ctx\",\"&default\",\"@analyticsLabel\",\"@testSelectorVal\",\"@class\",\"@target\"],\"statements\":[[4,\"unless\",[[23,0,[\"hidden\"]]],null,{\"statements\":[[4,\"let\",[[28,\"hash\",null,[[\"link\"],[[28,\"if\",[[23,0,[\"isEmber\"]],[28,\"component\",[\"osf-navbar/x-links/global-link-to\"],[[\"params\"],[[23,0,[\"_params\"]]]]],[28,\"component\",[\"osf-navbar/x-links/hyper-link/x-anchor\",[23,0,[\"resolvedRoute\"]]],null]],null]]]]],null,{\"statements\":[[0,\"        \"],[6,[23,1,[\"link\"]],[[12,\"data-analytics-name\",[29,[[23,3,[]]]]],[12,\"data-test-hyper-link\",[23,4,[]]],[12,\"class\",[23,5,[]]],[12,\"target\",[29,[[23,6,[]]]]]],[[\"@click\"],[[28,\"action\",[[23,0,[]],[23,0,[\"onclick\"]]],null]]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[25,2]],null,{\"statements\":[[0,\"                \"],[14,2],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"                \"],[1,[23,0,[\"text\"]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"        \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/osf-navbar/x-links/hyper-link/template.hbs"
    }
  });

  _exports.default = _default;
});