define("osf-components/components/files/selected-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SDQ78bEC",
    "block": "{\"symbols\":[\"file\",\"&attrs\",\"@selectedFiles\",\"@unselect\"],\"statements\":[[4,\"if\",[[23,3,[]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",false],[12,\"class\",[29,[[28,\"local-class\",[\"selected-list\"],[[\"from\"],[\"osf-components/components/files/selected-list/styles\"]]]]]],[12,\"data-test-selected-files\",\"\"],[13,2],[8],[0,\"\\n        \"],[5,\"animated-container\",[],[[],[]],{\"statements\":[[0,\"\\n            \"],[5,\"animated-each\",[],[[\"@items\",\"@use\",\"@duration\"],[[23,3,[]],[23,0,[\"transition\"]],500]],{\"statements\":[[0,\"\\n                \"],[7,\"div\",true],[11,\"class\",[29,[[28,\"local-class\",[\"selected-item\"],[[\"from\"],[\"osf-components/components/files/selected-list/styles\"]]]]]],[11,\"data-test-selected-file\",[29,[[23,1,[\"id\"]]]]],[8],[0,\"\\n                    \"],[7,\"span\",true],[8],[1,[23,1,[\"itemName\"]],false],[9],[0,\"\\n                    \"],[5,\"osf-button\",[[12,\"class\",[29,[[28,\"local-class\",[\"unselect\"],[[\"from\"],[\"osf-components/components/files/selected-list/styles\"]]]]]],[12,\"data-test-unselect-file\",\"\"],[12,\"data-analytics-name\",\"Unselect file\"],[12,\"aria-label\",[28,\"t\",[\"osf-components.files-widget.unselect_file\"],null]],[12,\"tabindex\",\"0\"]],[[\"@onClick\",\"@type\"],[[28,\"action\",[[23,0,[]],[23,4,[]],[23,1,[]]],null],\"link\"]],{\"statements\":[[0,\"\\n                        \"],[5,\"fa-icon\",[],[[\"@icon\",\"@fixedWith\"],[\"times\",true]]],[0,\"\\n                    \"]],\"parameters\":[]}],[0,\"\\n                \"],[9],[0,\"\\n            \"]],\"parameters\":[1]}],[0,\"\\n        \"]],\"parameters\":[]}],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/files/selected-list/template.hbs"
    }
  });

  _exports.default = _default;
});