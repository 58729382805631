define("osf-components/components/search-bar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xcZ9+2LR",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"data-test-search-bar\",\"\"],[8],[0,\"\\n    \"],[7,\"span\",true],[11,\"class\",[29,[[28,\"local-class\",[\"iconInput\"],[[\"from\"],[\"osf-components/components/search-bar/styles\"]]]]]],[10,\"data-analytics-category\",\"text\"],[8],[0,\"\\n        \"],[7,\"span\",true],[8],[0,\"\\n            \"],[5,\"fa-icon\",[],[[\"@icon\",\"@fixedWidth\"],[\"search\",true]]],[0,\"\\n        \"],[9],[0,\"\\n        \"],[5,\"input\",[[12,\"data-test-search-input\",\"\"],[12,\"aria-label\",[28,\"t\",[\"osf-components.search-bar.aria\"],null]]],[[\"@name\",\"@type\",\"@placeholder\",\"@enter\",\"@value\"],[\"search\",\"text\",[28,\"t\",[\"osf-components.search-bar.placeholder\"],null],[28,\"action\",[[23,0,[]],[23,0,[\"_onSearch\"]],[23,0,[\"value\"]]],null],[28,\"mut\",[[23,0,[\"value\"]]],null]]]],[0,\"\\n    \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/search-bar/template.hbs"
    }
  });

  _exports.default = _default;
});