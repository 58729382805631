define("osf-components/components/simple-paginator/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "SimplePaginator__element": "_SimplePaginator__element_kbljhu",
    "SimplePaginator__text": "_SimplePaginator__text_kbljhu"
  };
  _exports.default = _default;
});