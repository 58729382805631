define("osf-components/components/osf-link/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uTP73C1P",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[7,\"a\",false],[12,\"href\",[23,0,[\"_href\"]]],[12,\"target\",[23,0,[\"target\"]]],[12,\"rel\",[23,0,[\"rel\"]]],[12,\"class\",[29,[[23,0,[\"class\"]],\" \",[28,\"if\",[[23,0,[\"isActive\"]],\"active\"],null]]]],[12,\"onclick\",[28,\"action\",[[23,0,[]],[23,0,[\"_onClick\"]]],null]],[13,1],[8],[14,2,[[28,\"hash\",null,[[\"active\"],[[23,0,[\"isActive\"]]]]]]],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/osf-link/template.hbs"
    }
  });

  _exports.default = _default;
});