define("osf-components/components/file-list/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "FileList": "_FileList_nat2sh",
    "action-button": "_action-button_nat2sh",
    "actions-header": "_actions-header_nat2sh row",
    "small-display": "_small-display_nat2sh",
    "items": "_items_nat2sh",
    "shade": "_shade_nat2sh",
    "transparent": "_transparent_nat2sh"
  };
  _exports.default = _default;
});