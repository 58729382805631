define("osf-components/components/cookie-banner/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "CookieBanner": "_CookieBanner_1k6aw9",
    "Warning": "_Warning_1k6aw9",
    "Accept": "_Accept_1k6aw9"
  };
  _exports.default = _default;
});