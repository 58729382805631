define("osf-components/components/files/browse/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "header": "_header_1vokk4",
    "current-folder": "_current-folder_1vokk4",
    "file-browser-list": "_file-browser-list_1vokk4",
    "uploading": "_uploading_1vokk4",
    "files-list": "_files-list_1vokk4",
    "column": "_column_1vokk4",
    "sort-by": "_sort-by_1vokk4",
    "load-more": "_load-more_1vokk4",
    "load-more-button": "_load-more-button_1vokk4"
  };
  _exports.default = _default;
});