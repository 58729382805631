define("osf-components/components/registries/schema-block-group-renderer/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KpGy9+9p",
    "block": "{\"symbols\":[\"block\",\"block\",\"@renderStrategy\"],\"statements\":[[4,\"if\",[[23,0,[\"isFieldsetGroup\"]]],null,{\"statements\":[[0,\"    \"],[7,\"fieldset\",true],[11,\"class\",[29,[[28,\"local-class\",[\"Fieldset\"],[[\"from\"],[\"osf-components/components/registries/schema-block-group-renderer/styles\"]]]]]],[8],[0,\"\\n\"],[4,\"each\",[[23,0,[\"nonOptionBlocks\"]]],null,{\"statements\":[[0,\"            \"],[5,\"registries/schema-block-renderer\",[],[[\"@schemaBlock\",\"@optionBlocks\",\"@uniqueID\",\"@renderStrategy\",\"@isRequired\",\"@isFieldsetGroup\"],[[23,2,[]],[23,0,[\"optionBlocks\"]],[23,0,[\"uniqueID\"]],[23,3,[]],[23,0,[\"isRequired\"]],true]]],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"    \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"each\",[[23,0,[\"nonOptionBlocks\"]]],null,{\"statements\":[[0,\"        \"],[5,\"registries/schema-block-renderer\",[],[[\"@schemaBlock\",\"@optionBlocks\",\"@uniqueID\",\"@renderStrategy\",\"@isRequired\",\"@isFieldsetGroup\"],[[23,1,[]],[23,0,[\"optionBlocks\"]],[23,0,[\"uniqueID\"]],[23,3,[]],[23,0,[\"isRequired\"]],false]]],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/registries/schema-block-group-renderer/template.hbs"
    }
  });

  _exports.default = _default;
});