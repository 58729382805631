define("osf-components/components/registries/hierarchical-list/item/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "expand-button": "_expand-button_1mnrma"
  };
  _exports.default = _default;
});