define("ember-cli-sanitize-html/components/sanitize-html", ["exports", "ember-cli-sanitize-html", "ember-cli-sanitize-html/templates/components/sanitize-html"], function (_exports, _emberCliSanitizeHtml, _sanitizeHtml) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _sanitizeHtml.default,
    raw: false,
    value: null,
    options: null,
    classNames: ['sanitized-value'],
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this.get('sanitizedValue');
    },
    sanitizedValue: Ember.computed('value', 'options', function () {
      var options = this.get('options');
      var value = this.get('value') || '';
      return (0, _emberCliSanitizeHtml.default)(value, options) || '';
    })
  });

  _exports.default = _default;
});