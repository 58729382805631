define("osf-components/components/registries/schema-block-renderer/read-only/rdm/array-input/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "h5VCRh5b",
    "block": "{\"symbols\":[\"sub\",\"group\",\"@node\",\"@schemaBlockGroup\"],\"statements\":[[4,\"each\",[[23,0,[\"subs\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[11,\"class\",[29,[[28,\"local-class\",[\"array-input-item-container\"],[[\"from\"],[\"osf-components/components/registries/schema-block-renderer/read-only/rdm/array-input/styles\"]]]]]],[8],[0,\"\\n\"],[4,\"each\",[[23,4,[\"children\"]]],null,{\"statements\":[[0,\"            \"],[5,\"registries/schema-block-group-renderer\",[],[[\"@schemaBlockGroup\",\"@renderStrategy\"],[[23,2,[]],[28,\"component\",[\"registries/schema-block-renderer/read-only/mapper\"],[[\"registrationResponses\",\"changeset\",\"node\",\"draftManager\",\"schemaBlockGroup\"],[[23,1,[\"subRegistrationResponse\"]],[23,1,[\"subChangeset\"]],[23,3,[]],[23,0,[\"draftManager\"]],[23,2,[]]]]]]]],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"    \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/registries/schema-block-renderer/read-only/rdm/array-input/template.hbs"
    }
  });

  _exports.default = _default;
});