define("osf-components/components/node-publication-doi/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aKSUTIUU",
    "block": "{\"symbols\":[\"&attrs\"],\"statements\":[[5,\"osf-link\",[[13,1],[12,\"data-analytics-name\",\"Publication DOI\"]],[[\"@href\"],[[23,0,[\"publicationDoiUrl\"]]]],{\"statements\":[[0,\"\\n    \"],[1,[23,0,[\"publicationDoiUrl\"]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/node-publication-doi/template.hbs"
    }
  });

  _exports.default = _default;
});