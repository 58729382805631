define("osf-components/components/registries/review-metadata-renderer/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "NoResponse": "_NoResponse_g8u9ry",
    "TextResponse": "_TextResponse_g8u9ry",
    "NestedProperty": "_NestedProperty_g8u9ry",
    "ValidationErrors": "_ValidationErrors_g8u9ry _Element_t890a9"
  };
  _exports.default = _default;
});