define("osf-components/components/contributor-list/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "load-contribs": "_load-contribs_1kpb84"
  };
  _exports.default = _default;
});