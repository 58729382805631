define("@ember-intl/decorators/index", ["exports", "@ember-intl/decorators/t", "@ember-intl/decorators/intl"], function (_exports, _t, _intl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "t", {
    enumerable: true,
    get: function get() {
      return _t.default;
    }
  });
  Object.defineProperty(_exports, "raw", {
    enumerable: true,
    get: function get() {
      return _t.raw;
    }
  });
  Object.defineProperty(_exports, "intl", {
    enumerable: true,
    get: function get() {
      return _intl.default;
    }
  });
});