define("osf-components/components/validated-input/textarea/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6OpOPCTM",
    "block": "{\"symbols\":[\"inputElementId\",\"@onKeyUp\",\"@uniqueID\"],\"statements\":[[4,\"validated-input/x-input-wrapper\",null,[[\"model\",\"changeset\",\"errors\",\"label\",\"valuePath\",\"validationStatus\"],[[23,0,[\"model\"]],[23,0,[\"changeset\"]],[23,0,[\"errors\"]],[23,0,[\"label\"]],[23,0,[\"valuePath\"]],[23,0,[\"validationStatus\"]]]],{\"statements\":[[0,\"    \"],[1,[28,\"textarea\",null,[[\"id\",\"value\",\"placeholder\",\"class\",\"name\",\"disabled\",\"keyUp\",\"__HTML_ATTRIBUTES__\"],[[28,\"or\",[[23,3,[]],[23,1,[]]],null],[23,0,[\"value\"]],[23,0,[\"_placeholder\"]],\"form-control\",[23,0,[\"valuePath\"]],[23,0,[\"disabled\"]],[23,2,[]],[28,\"hash\",null,[[\"aria-label\"],[[23,0,[\"ariaLabel\"]]]]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/validated-input/textarea/template.hbs"
    }
  });

  _exports.default = _default;
});